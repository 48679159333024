import React, { useEffect, useState } from "react";
import Modal from "react-modal";

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: '0',
    padding: '0',
    maxWidth: '700px',
    borderRadius: '30px',
    overflow: 'hidden',
    backgroundColor: 'transparent'
  },
};
Modal.setAppElement('#root');

function Result(p) {
  
  const [modalIsOpen, setIsOpen] = useState(false);
  
  const url = "https%3A%2F%2Fexplorestem2d.fhi360.org%2Findex.html";
  const appId = "578673766177932";
  const quote = `${p.copy.results.shareText} ${p.career.displayName}! ${p.career.description}`
    .split(" ")
    .join("%20");
  const tweet = `${p.copy.results.shareText} ${p.career.displayName}!`
    .split(" ")
    .join("%20");

  const getFacebookLink = () => {
    return `https://www.facebook.com/dialog/share?href=${url}%3Fsource%3Dfacebook%26lang%3D${
      p.language === "spanish" ? "esp" : "en"
    }&app_id=${appId}&quote=${quote}`;
  };
  const getTwitterLink = () => {
    return `https://twitter.com/intent/tweet?text=${tweet}&url=${url}%3Fsource%3Dtwitter%26lang%3D${
      p.language === "spanish" ? "esp" : "en"
    }&hashtags=WISTEM,WISTEM2D`;
  };
  
  function openModal() {
    setIsOpen(true);
  }
  function afterOpenModal() {
  }
  function closeModal() {
    setIsOpen(false);
  }
 
  useEffect(() => {
    window.ga("send", "event", "QuizComplete");
  }, []);
  return p.showAllResults || p.index <= 9 ? (
    <section className="result">
      <header className="result-header">
        <div className="number">
          <p>{`${p.index + 1}`}</p>
        </div>
        <h1>{`${p.career.displayName}`}</h1>
      </header>
      {typeof p.career.score !== "undefined" && (
        <p className="debug">{`score: ${p.career.score}/${
          p.career.maxScore
        } (${parseInt((p.career.score / p.career.maxScore) * 100)}%)`}</p>
      )}
      {/*
      <section className="links-holder">
        <a
          href={getFacebookLink()}
          target="blank"
          onClick={() => {
            window.ga(function () {
              console.log("shared Facebook link");
              window.ga(
                "send",
                "event",
                "Link",
                "facebookShare",
                p.career.displayName
              );
            });
          }}
        >
          <i className="fa fa-facebook-square" aria-hidden="true"></i>
        </a>
        <a
          href={getTwitterLink()}
          target="blank"
          onClick={() => {
            window.ga(function () {
              console.log("shared Twitter link");
              window.ga(
                "send",
                "event",
                "Link",
                "twitterShare",
                p.career.displayName
              );
            });
          }}
        >
          <i className="fa fa-twitter-square" aria-hidden="true"></i>
        </a>
      </section>*/}
      
      <div>
        {p.career.expertName ? <button className="expert-button" onClick={openModal}>Meet an Expert: {p.career.expertName}</button> : ''}
        <Modal
         isOpen={modalIsOpen}
         onAfterOpen={afterOpenModal}
         onRequestClose={closeModal}
         style={customStyles}
        >
        <div className="expert-inner">
            <div className="expert-description">
              <div className="expert-desktop-name">
                <h2>{p.career.expertName}</h2>
                {p.career.expertTitle ? <h3>{p.career.expertTitle}</h3> : ''}
              </div>
              <p>{p.career.expertDesc}</p>
              {p.career.expertCredit ? <div className="expert-credit">PHOTO CREDIT: {p.career.expertCredit}</div> : ''}
            </div>
          
            <div className="expert-image">
              <button className="expert-close" onClick={closeModal}></button>
              <img src={p.career.expertPhoto} alt=""/>
              <div className="expert-mobile-name">
                <h2>{p.career.expertName}</h2>
                {p.career.expertTitle ? <h3>{p.career.expertTitle}</h3> : ''}
              </div>
            </div>
        </div>
        </Modal>
      </div>

      <div
        className={`expandable-description ${
          p.careersExpanded[p.index] ? "expanded" : "hidden"
        }`}
      >
        <p className="description-text">{p.career.description}</p>
      </div>
      <button
        onClick={() => {
          const newArray = [...p.careersExpanded];
          newArray[p.index] = !newArray[p.index];
          p.setCareersExpanded(newArray);
        }}
      >
        {!p.careersExpanded[p.index]
          ? `${p.copy.results.readMore} +`
          : `${p.copy.results.hide} -`}
      </button>
    </section>
  ) : (
    <></>
  );
}
export default Result;
