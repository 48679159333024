const careers = [
  {
    id: "actuary",
    displayName: "Actuaria",
    description:
      "Un actuario ayuda a garantizar que las personas estén protegidas de diferentes riesgos, como desastres naturales, enfermedades o accidentes. Los actuarios usan las matemáticas para ayudar a las compañías de seguros a darles tranquilidad a las personas, al saber que recibirán atención si necesitan ayuda. Por ejemplo, si se enferman y deben enfrentar altas facturas del hospital, o si su casa se daña en una inundación o incendio, recibirán ayuda financiera para cubrir los costos. Un actuario también puede viajar a diferentes lugares para trabajar con los gobiernos y ayudarlos a comprender y prepararse para los grandes riesgos a nivel sociedad, como el cambio climático, las epidemias y las crisis económicas.",
  },
  {
    id: "oncologist",
    displayName: "Oncóloga matemática",
    description:
      "Los matemáticos pueden ayudar a los investigadores, médicos y miembros del personal médico a superar el cáncer mediante el uso de las matemáticas para comprender la tasa de crecimiento de los tumores. Los oncólogos matemáticos son una parte importante del equipo en el campo de la oncología, que es el área de la medicina que maneja el tratamiento del cáncer. Desempeñan un papel importante en la investigación del cáncer al medir y predecir cómo las células cancerosas pueden crecer y viajar a otras partes del cuerpo (metástasis) a través del modelado y la simulación. Los oncólogos matemáticos pueden usar los datos que recopilan para comprender cómo las células cancerosas responderán a los diferentes tratamientos, lo que puede salvar vidas.",
  },
  {
    id: "dataPro",
    displayName: "Profesional de datos",
    description:
      "Un profesional de datos puede incluir muchas carreras diferentes, como analista de datos, arquitecto de datos, ingeniero de datos o científico de datos. ¿Qué tienen todas ellos en común? Estos profesionales usan estadísticas (una rama de la matemática) para comprender y resolver los complejos problemas del mundo. Organice, gestione e interprete grandes cantidades de datos para llegar al panorama COMPLETO. ¿Cómo podemos detener una pandemia? ¿Qué podemos hacer para proteger las especies en peligro de extinción? ¿Cómo pueden los agricultores cultivar más alimentos de manera más eficiente para ayudar a combatir el hambre? Puede usar números para responder estas preguntas y marcar una diferencia significativa en cualquier causa que le interese.",
  },
  {
    id: "architect",
    displayName: "Arquitecta o proyectista civil",
    description:
      "Los arquitectos y proyectistas civiles diseñan los edificios y espacios que conforman nuestro mundo. Diseñan todo, desde casas y enormes rascacielos hasta parques y ciudades enteras. Usted puede dar vida a hermosos espacios desde su imaginación para que las personas puedan vivir en ellos y disfrutarlos. Es posible que se especialice en condiciones climáticas extremas y diseñe edificios que se mantendrán en pie tras un terremoto, o que se enfoque en una arquitectura sostenible para hacer que los edificios sean eficientes en términos energéticos (o ecológicos) a fin de ayudar al planeta. Incluso podría concentrarse en diseñar hábitats animales increíbles, un tipo especial de arquitectura del paisaje en la cual trabajará con zoólogos.",
  },
  {
    id: "carDesigner",
    displayName: "Diseñadora, ingeniera o fabricante de automóviles",
    description:
      "Siendo profesional en industria automotriz, puede dar vida a las innovaciones de automóviles más emocionantes. Ya sea que trabajen en la parte de diseño, ingeniería o fabricación del proceso, las personas que crean los autos que manejamos no solo se enfocan en su estética y diversión. También trabajan duro para crear funciones de seguridad inteligentes que eviten accidentes automovilísticos y salven vidas. Además, desarrollan automóviles ecológicos que funcionan con electricidad o combustibles limpios, creando aire más limpio y protegiendo al medio ambiente de los gases nocivos del efecto invernadero que causan el calentamiento global.",
  },
  {
    id: "biostatistician",
    displayName: "Bioestadística",
    description:
      "Combinando los campos de la biología y las estadísticas (una rama de la matemática), los bioestadísticos observan los datos de la investigación médica y los analizan para comprender mejor cómo mantener a las personas sanas y saludables. A menudo trabajan con otros investigadores y científicos. Por ejemplo, los científicos de la NASA trabajan con bioestadísticos para estudiar cómo el espacio exterior y la baja gravedad afectan el cuerpo humano, a fin de poder proteger a los astronautas contra daños durante largos viajes espaciales. Los bioestadísticos también pueden ayudar con los ensayos clínicos para determinar cuán efectivo es un nuevo medicamento para tratar una enfermedad, o para evaluar cuán efectiva es la protección de una vacuna contra un virus peligroso.",
  },
  {
    id: "recycleManufacture",
    displayName: "Fabricante de materiales sostenibles o reciclados",
    description:
      "Use sus manos para crear productos cotidianos con materiales que sean favorables para el planeta. Cuando reciclamos, todo un equipo de personas está detrás de escena convirtiendo esos materiales reciclados en cosas nuevas. Los clasificadores separan materiales como plástico y vidrio; los ensambladores y fabricantes convierten los materiales reciclados en nuevos productos; y los gerentes de las instalaciones se aseguran de que todo funcione sin problemas y de forma segura. Este equipo convierte la basura de ayer en el tesoro de mañana. Otros materiales sostenibles también son críticos para la fabricación. En la fabricación de ropa, materiales como el lienzo y la piel sintética crean hermosos diseños de moda que no dañan a los animales. Para los constructores de muebles, la madera recuperada se puede utilizar para crear hermosas mesas y aparadores que sean duraderos y respetuosos con el medio ambiente.",
  },
  {
    id: "printTech",
    displayName: "Técnica de impresión 3D",
    description: `El apasionante mundo de la impresión 3D puede marcar una gran diferencia. A veces llamada fabricación aditiva, utiliza tecnología para "imprimir" objetos 3D desde un diseño de computadora. Es precisa, por lo que un técnico de impresión 3D puede replicar objetos del mundo real que se ven y se sienten como si fueran reales. Entonces, ¿qué pasaría si pudiera imprimir sustratos de arrecifes de coral para regenerar los ecosistemas de coral bajo el agua y salvar especies en peligro de extinción? ¿O imprimir materiales de construcción de bajo costo para reconstruir una ciudad después de un desastre natural? ¿Qué pasaría si pudiera imprimir prótesis, o extremidades artificiales, para ayudar a alguien que ha perdido una pierna a caminar nuevamente? Los técnicos de impresión 3D hacen que esto suceda.`,
  },
  {
    id: "hacker",
    displayName: "Hacker ética",
    description:
      "Los hackers éticos son científicos informáticos y programadores que pueden ayudar a una empresa u organización a determinar si son vulnerables a la piratería, la acción mediante la cual los delincuentes obtienen acceso a redes y sistemas informáticos para robar información digital, dinero o las identidades de sus empleados. Los hackers éticos pueden tener las mismas habilidades que los piratas informáticos, pero eligen usarlas para fines legítimos. Trabajan con la compañía para encontrar puntos débiles en los cuales podrían entrar los piratas informáticos, y ayudan a aumentar la seguridad para protegerla de esa amenaza. Los hackers éticos incluso pueden viajar hacia diferentes compañías para proporcionar sus servicios.",
  },
  {
    id: "mechDrafter",
    displayName: "Diseñadora mecánica",
    description:
      "Los diseñadores mecánicos dibujan diagramas detallados de máquinas y dispositivos mecánicos y ayudan a ingenieros, arquitectos y constructores a darles vida. Según su interés, hay muchas formas de apoyar el trabajo en este campo. Podría ser parte del equipo creando nueva tecnología médica que salva vidas en hospitales, diseñando sistemas de tránsito nuevos y eficientes para proporcionar transporte público limpio que ayude al medio ambiente, o dibujando robots complejos que pueden enviarse a entornos peligrosos en lugar de personas que arriesgan sus vidas.",
  },
  {
    id: "biomed",
    displayName: "Ingeniera de nanotecnología biomédica",
    description:
      "La nanotecnología es un campo de ingeniería revolucionario que está cambiando la faz de la ciencia, la tecnología, el medio ambiente y la salud. Los ingenieros de nanotecnología desarrollan y trabajan con materiales en una escala muy pequeña (a nivel molecular) para resolver problemas y facilitar la vida. Un especialista biomédico en este campo podría usar partículas diminutas para encontrar y matar células cancerosas, o usar biomateriales en ingeniería de tejidos para reemplazar tejidos y órganos dañados por enfermedades o lesiones. A menudo, esta carrera incluye investigación en un laboratorio, realización de experimentos y uso de tecnología avanzada. Estos sorprendentes descubrimientos de investigación están cambiando el campo de la medicina para ayudar a las personas de todo el mundo.",
  },
  {
    id: "elecEngineer",
    displayName: "Ingeniera eléctrica",
    description:
      "Los ingenieros eléctricos son solucionadores de problemas creativos, utilizan las matemáticas de maneras inusuales para crear sistemas de energía eficientes. La energía está en todas partes, por lo que los ingenieros eléctricos pueden desarrollar una carrera en cualquier parte del mundo. Con muchos subcampos, sus intereses específicos pueden brillar en esta área. Los ingenieros eléctricos ayudan a [DP1] a diseñar automóviles eléctricos para disminuir la contaminación del gas y del aire venenoso. Los ingenieros ópticos trabajan con fibra óptica para proporcionar acceso a Internet en todo el mundo. Los ingenieros informáticos construyen computadoras más rápidas que usan menos energía. Los recién llegados al campo están constantemente ayudando a transformar la industria. La diversidad de puntos de vista aporta creatividad, por lo cual se espera que trabaje en equipo con otros ingenieros a fin de tratar de hacer del mundo un lugar mejor.",
  },
  {
    id: "sportsHealth",
    displayName:
      "Proveedora de atención médica especializada en medicina deportiva",
    description:
      "Desde entrenadores de atletismo hasta médicos especializados en medicina deportiva, los proveedores de atención médica especializados en medicina deportiva tienen capacitación especial para tratar lesiones relacionadas con el atletismo. Pueden ayudar a atletas profesionales, personas con trabajos físicamente exigentes, niños y adolescentes que practican deportes recreativos, y otros adultos que se lastiman mientras hacen ejercicio. Estos profesionales de la salud podrían especializarse en lesiones musculares, cirugía ortopédica, conmociones (lesiones) cerebrales y entrenamiento deportivo. Cualquiera sea el enfoque, todos trabajan para ayudar a las personas a sanar después de lesiones de alto impacto o a alcanzar el máximo de sus capacidades físicas.",
  },
  {
    id: "conservationSci",
    displayName: "Científica conservacionista",
    description:
      "Los científicos conservacionistas se preocupan por salvaguardar los recursos naturales del mundo. A menudo trabajan al aire libre (a veces, viajan a bosques, parques públicos y áreas remotas) para ayudar a proteger el agua, la tierra, los hábitats de vida silvestre o las plantas. Un conservacionista puede trabajar con los ganaderos para evitar que los animales pastoreen en exceso y amenacen los pastizales; para asesorar a los agricultores sobre las mejores formas de preservar el agua que utilizan para sus cultivos; o para estudiar cómo proteger los arrecifes de coral y todos los animales marinos que viven en ellos. Su pasión por la naturaleza beneficia a todos, incluidos los animales y las personas que viven y disfrutan de ella.",
  },
  {
    id: "wildBio",
    displayName: "Bióloga de vida silvestre",
    description:
      "Como bióloga de vida silvestre, puede estudiar la biología, el comportamiento y los hábitats de sus animales salvajes favoritos. Explore las selvas de Sri Lanka para estudiar cómo proteger a los leopardos nativos de la extinción, o viaje a las llanuras del Serengueti para ayudar a clasificar y reubicar rinocerontes, jirafas y elefantes salvajes y salvar ecosistemas vulnerables. Investigue el impacto de los proyectos comerciales en las poblaciones locales de vida silvestre, y proporcione a los gobiernos locales recomendaciones para proteger los hábitats de tierra y agua. Los biólogos de vida silvestre trabajan en equipos de científicos tanto en la naturaleza como en un laboratorio, y el trabajo puede incluir experimentos científicos y viajar a lugares remotos de todo el mundo.",
  },
  {
    id: "lifeScience",
    displayName: "Especialista en fabricación de ciencias biológicas",
    description:
      "Imagine que los investigadores acaban de presentar una nueva vacuna contra un virus que está infectando a personas de todo el mundo. Luego, esta vacuna debe ser producida en masa, y rápidamente, para que las personas puedan recibirla lo antes posible. Los especialistas en fabricación son algunas de las personas responsables de obtener las vacunas, así como otros medicamentos para personas y animales, desde el laboratorio de un investigador hasta su farmacia o veterinario local. Estos especialistas pueden apoyar la producción de la vacuna en lotes asegurándose de que el equipo de fabricación funcione correctamente, los productos se trasladen eficientemente a través de la línea de producción y se sigan las pautas de seguridad.",
  },
  {
    id: "envScience",
    displayName: "Científica ambiental",
    description:
      "Aplique su amor por la ciencia para resolver problemas ambientales como la contaminación, y de esta manera podrá ayudar al planeta, así como a las personas y animales que viven en él. Los científicos ambientales suelen enfocarse en formas de reducir la contaminación del aire, el agua y el suelo. Pueden incluir especialistas que recolectan muestras de agua y tierra para analizar químicos tóxicos o radiación; investigadores en el laboratorio que realizan las pruebas; y buscadores curiosos que viajan para investigar las fuentes de contaminación. Los científicos ambientales a menudo trabajan con un equipo de personas dedicadas a lograr el mismo objetivo, como hacer que el aire que respiramos sea más limpio o prevenir el cambio climático.",
  },
  {
    id: "webDesign",
    displayName: "Diseñadora creativa o desarrolladora web",
    description:
      "Las empresas comerciales y las organizaciones sin fines de lucro que realizan un trabajo significativo necesitan artistas talentosos para contar sus historias. Use sus habilidades creativas y su imaginación para crear hermosas imágenes y sitios web increíbles para la causa que le interesa. Diseñe anuncios imaginativos que alienten a las personas a reciclar y conservar el agua. Edite fotos impresionantes de perros y gatos en adopción para ayudar a las mascotas del rescate local a encontrar sus hogares para siempre. Cree sitios web para organizaciones de derechos humanos que ayuden a las personas a apoyar sus causas. Puede trabajar en un equipo o ser su propio jefa como diseñadora independiente que ayuda a sus clientes a marcar la diferencia en el mundo.",
  },
  {
    id: "robotEngineer",
    displayName: "Ingeniera o Técnica en Robótica",
    description:
      "Los ingenieros de robótica diseñan y programan robots, mientras que los técnicos de robótica se centran en probarlos, operarlos, mantenerlos y repararlos. Ambos utilizan tecnología e ingeniería innovadoras para crear robots con inteligencia artificial que pueden proteger el planeta y salvar la vida de las personas. Hoy en día, los robots que luchan contra incendios y pueden soportar el calor y el humo están ayudando a los socorristas a apagar las llamas; los drones con cámaras de alta resolución están ayudando a los equipos de búsqueda y rescate a encontrar rápidamente a las personas que necesitan salvarse; y toneladas de bots avanzados están ayudando al planeta limpiando basura y contaminantes del océano, plantando árboles, cosechando energía solar y haciendo posible un transporte limpio. ¿Qué los ayudará a hacer usted mañana?",
  },
  {
    id: "healthSpecialist",
    displayName: "Especialista en atención médica",
    description:
      "La atención médica consiste en brindar cuidados a los pacientes para mejorar su salud y bienestar. Incluye prevenir, diagnosticar y tratar todo tipo de enfermedades y lesiones. No es sorprendente que haya una variedad de diferentes opciones de carreras en este campo. Enfermeros, médicos, asistentes médicos, flebotomistas, inmunólogos, epidemiólogos y dietistas trabajan juntos para mejorar la salud de las personas. Muchas organizaciones benéficas de ayuda también ofrecen la oportunidad de brindar atención médica en todo el mundo. Los trabajos vinculados a la atención médica son tremendamente gratificantes. No importa qué rol cumpla en el equipo, ¡siempre podrá contribuir a salvar vidas!",
  },
  {
    id: "scienceTech",
    displayName: "Técnica en ciencias",
    description:
      "Los técnicos en ciencias trabajan en campos que van desde la ciencia animal hasta la ciencia forense y la ciencia de los alimentos. Utilizan el método científico para realizar experimentos e investigaciones en su campo de elección. Por ejemplo, los técnicos químicos pueden experimentar y probar diferentes productos para asegurarse de que no dañen a las personas ni al medio ambiente. Los técnicos nucleares monitorean los niveles de radiación para asegurarse de que no haya fugas de radiación. Además, analizan el aire y el agua en busca de contaminación y emiten procedimientos de seguridad si se encuentra radiación. Los técnicos de teledetección utilizan herramientas ópticas avanzadas como imágenes satelitales e infrarrojas para examinar los impactos ambientales y recomendar cambios a funcionarios del gobierno.",
  },
  {
    id: "vet",
    displayName: "Veterinaria o Rehabilitadora de vida silvestre",
    description:
      "Use la medicina veterinaria para sanar a nuestros amigos peludos, escamosos o emplumados, desde queridas mascotas hasta animales salvajes que viven en ecosistemas exóticos de todo el mundo. Use su pasión por ayudar a los animales para cuidar a los perros, gatos y pájaros de las personas en un hospital veterinario local; ayude a entregar potros (crías de caballos) a las granjas cercanas; o incluso tratar elefantes rescatados en un refugio de vida silvestre en Tailandia.",
  },
  {
    id: "softwareDev",
    displayName: "Ingeniera de aplicaciones o desarrolladora de software",
    description:
      "Use un diseño centrado en el ser humano a fin de crear aplicaciones para teléfonos inteligentes o programas de computadora que ayuden a las personas. Estos líderes tecnológicos crean programas innovadores que pueden cambiar la vida de la gente. Algunos ejemplos incluyen la tecnología para evitar colisiones que advierte a los conductores si corren el riesgo de sufrir un accidente automovilístico; tecnología accesible que ayuda a las personas con discapacidad; o aplicaciones que promueven la salud mental en adolescentes. Con una carrera en este campo, puede utilizar la tecnología innovadora de muchas maneras para marcar una diferencia en el mundo.",
  },
  {
    id: "envEngineer",
    displayName: "Ingeniera ambiental, energética o de agua",
    description:
      "Hay muchas carreras diferentes en el campo de la ingeniería que se enfocan en proporcionar a las personas aire limpio, energía y agua para mejorar sus vidas. Los ingenieros ambientales trabajan para prevenir y controlar peligros como la contaminación, el agua potable no segura, el cambio climático o los desechos peligrosos. Podrían realizar pruebas y fabricar equipos para medir y reducir la contaminación del aire; diseñar potentes aerogeneradores para generar energía sostenible; o construir estructuras que brinden agua potable a comunidades remotas de todo el mundo. Todas estas carreras utilizan conceptos y tecnologías de ingeniería civil y mecánica para resolver problemas globales, haciendo que las personas y el planeta sean más saludables.",
  },
  {
    id: "urbGrower",
    displayName: "Productora urbana",
    description:
      "Los productores urbanos trabajan en la agricultura de una manera que no podría imaginarse. En lugar de cultivar frutas y verduras en granjas rurales, se centran en cultivarlas en los jardines de los tejados de las grandes ciudades. Los productores urbanos no solo reducen las emisiones de dióxido de carbono, dado que no es necesario entregar los alimentos desde un lugar lejano, sino que también mejoran la calidad del aire en las ciudades al cultivar plantas que producen oxígeno. Realizan todo esto mientras proporcionan alimentos saludables y nutritivos a las personas en su comunidad. Quizás le gusta la ciencia... ¡y también tiene buena mano para las plantas!",
  },
  {
    id: "foodChemist",
    displayName: "Química de alimentos o medicamentos",
    description:
      "Tal vez ha realizado experimentos de química en la escuela o en casa con ácidos, líquidos con gas, cristales o limo. ¿Sabía que la química también es la ciencia detrás de los alimentos que comemos todos los días y los medicamentos que tomamos cuando estamos enfermos? Los tecnólogos de sabores realizan experimentos para crear nuevos sabores para alimentos y bebidas, y suelen trabajar para hacer deliciosas comidas nutritivas a fin de alentar a las personas a comer alimentos saludables. Los químicos de alimentos también podrían trabajar para aumentar la vida útil de los alimentos, brindar protección contra enfermedades transmitidas por los alimentos, o crear nuevos alimentos a base de plantas como alternativas a la carne, que pueden ser mejores para el medio ambiente. Los químicos medicinales desarrollan los compuestos químicos que se encuentran en el analgésico que usted toma cuando tiene dolor de cabeza, así como medicamentos que salvan vidas y que ayudan a las personas en todo el mundo.",
  },
  {
    id: "productSafety",
    displayName: "Ingeniera en seguridad de productos",
    description:
      "Los ingenieros en seguridad de productos conocen la mejor manera de hacer las cosas de manera eficiente y segura. Utilizan estas habilidades para probar productos comerciales nuevos e inventivos para garantizar su seguridad, desde la cuna de un niño hasta un reactor nuclear en una planta de energía. Asuma un rol de liderazgo al evaluar productos para identificar problemas peligrosos con el diseño o los materiales y hacer recomendaciones sobre cómo mejorar la seguridad. En esta carrera, incluso puede viajar de fábrica en fábrica para verificar los estándares de seguridad. Ya sea que esté probando el peligro de asfixia de un juguete nuevo o asegurándose de que una nueva solución de limpieza no se mezcle con productos químicos domésticos para crear reacciones peligrosas, ¡puede ayudar a mantener a millones de personas a salvo de daños!",
  },
  {
    id: "laserTech",
    displayName: "Técnica o ingeniera láser",
    description:
      "Los láseres son más que simplemente ciencia ficción. Hoy en día, son responsables de todo, desde cirugías correctivas oculares hasta imágenes por láser. Como ingeniero láser, usted puede desarrollar sistemas avanzados que pueden cortar diamantes y otros materiales ásperos, o puede ayudar en el diseño de fibra óptica que brinda Internet de alta velocidad. Los láseres son muy precisos, por lo que la atención al detalle es fundamental para los técnicos que los operan. Esta tecnología ha llevado a saltos masivos en los campos quirúrgicos, donde puede ayudar a corregir la vista de una persona para que pueda ver claramente por primera vez, o ayudar en la cirugía reconstructiva para hacer crecer la piel o los huesos de las personas que han sufrido accidentes trágicos.",
  },
  {
    id: "commercialDesign",
    displayName: "Diseñadora comercial e industrial",
    description:
      "¿Tiene un don para el diseño? ¿Dibuja hermosos proyectos de moda o redecora su habitación en su tiempo libre? Los diseñadores comerciales e industriales combinan belleza y funcionalidad creando productos que hacen la vida más fácil y más agradable. Los diseñadores de moda trabajan con ropa colorida, joyas y accesorios; los diseñadores de interiores crean hermosos espacios; y los diseñadores comerciales elaboran elegantes artículos para el día a día, como auriculares inalámbricos o modernas botellas de agua reutilizables. Puede dar vida a su imaginación a través de aplicaciones importantes del mundo real.",
  },
  {
    id: "scanner",
    displayName: "Especialista en escaneo 3D",
    description: `Los especialistas en escaneo 3D utilizan tecnología para escanear objetos del mundo real y recrearlos digitalmente. Podría usar drones para capturar escaneos detallados de entornos naturales para construir mundos de videojuegos, o escanear actores, accesorios y escenarios para obtener espectaculares efectos especiales en películas. Incluso podría viajar a sitios históricos increíbles para crear copias de seguridad digitales de hitos antiguos. Los increíbles escaneos 3D de la Catedral de Notre Dame en París permitirán reconstruir la estructura de casi 1000 años de antigüedad con todo su esplendor anterior después de haber sido prácticamente destruida por un terrible incendio en 2019.`,
  },
  {
    id: "sportStat",
    displayName: "Estadista deportiva",
    description:
      "Los estadistas deportivos usan las matemáticas para predecir qué tan bien se desempeñarán los jugadores y los equipos en su deporte. Son excelentes con los números, recopilan y organizan grandes conjuntos de datos y comprenden lo que se dice sobre los atletas del mundo real y sus desempeños. Este trabajo requiere un pensamiento abstracto y poder mirar el panorama general. Desarrollan programas de computadora y usan modelos matemáticos para mostrar cómo los momentos individuales, como la puntuación de un jugador, afectarán el resultado del juego. Esta es una gran carrera si usted es buena en matemáticas y ama los deportes.",
  },
  {
    id: "patientSafety",
    displayName: "Oficial de seguridad del paciente",
    description:
      "Si alguna vez ha estado en un centro de salud como un hospital o consultorio médico, sabrá que el personal puede estar muy ocupado. Se necesitan muchas piezas móviles para que estos lugares funcionen sin problemas, y la colaboración entre médicos, enfermeras y otros miembros del personal es clave. Los oficiales de seguridad del paciente trabajan con proveedores de atención médica y otros miembros del personal del edificio para hacer que sus instalaciones sean lo más seguras posible para los pacientes. Pueden crear un programa de capacitación para enseñarles a los médicos y enfermeras cómo prevenir la propagación de bacterias peligrosas entre los pacientes, o analizar accidentes para averiguar si el personal cometió un error y evitar que eso vuelva a suceder. Los oficiales de seguridad del paciente leen las últimas investigaciones para garantizar la seguridad y el bienestar del paciente.",
  },
  {
    id: "opticalPhys",
    displayName: "Física óptica",
    description:
      "Los físicos ópticos trabajan en espacios reales y abstractos. Pueden hacer preguntas teóricas; por ejemplo, “¿Cómo interactúan las propiedades de la luz con la materia?”, y usan estas preguntas para avanzar en el campo de la investigación óptica. O bien, pueden trabajar en estrecha colaboración con los ingenieros y hacer preguntas más prácticas; por ejemplo, “¿Cómo se pueden usar los láseres para mejorar la vista de las personas?”. Como profesionales de alto nivel con mentalidad matemática, los físicos ópticos brindan experiencia en diversas industrias, desde el desarrollo de tecnologías médicas hasta el fortalecimiento de los sistemas de detección de misiles.",
  },
  {
    id: "industrialEco",
    displayName: "Ecologista industrial",
    description:
      "La ecología es una rama de la biología que se ocupa de cómo los organismos interactúan entre sí y con su entorno. En otras palabras, estudia cómo las diferentes especies viven juntas y cómo afectan al mundo que las rodea, desde pequeñas bacterias hasta el elefante africano. Los ecologistas industriales toman conceptos de la ecología y los usan para resolver problemas humanos complejos, como la contaminación. Por ejemplo, de la misma manera que un pez lechón se adhiere a un tiburón y vive de sus desechos, una compañía de biocombustibles puede trabajar con una cadena de restaurantes para convertir la grasa de la cocina vieja en combustible más limpio para los autos, de modo que no termine en el desagüe. Esta carrera requiere habilidades de pensamiento abstracto y resolución creativa de problemas, y los resultados pueden tener un gran impacto en el planeta.",
  },
  {
    id: "microbio",
    displayName: "Microbióloga",
    description:
      "Los microbiólogos pueden estudiar organismos pequeños, ¡pero las oportunidades en este campo no son para nada pequeñas! Con compañeros de equipo inteligentes y numerosos equipos de laboratorio, cuando ingrese al campo de la microbiología, se sumergirá en el mundo de las bacterias, algas, hongos, parásitos e incluso virus. Hay muchas áreas de enfoque dentro de la microbiología que van desde la comprensión del crecimiento bacteriano en las tuberías hasta el desarrollo de vacunas para brotes de enfermedades. Es posible que desee especializarse en un campo como bacteriología o parasitología, trabajar en un laboratorio de salud pública para tratar una variedad de organismos diferentes que causan enfermedades, o investigar cómo los componentes de las paredes celulares de las plantas podrían usarse para crear biocombustibles más ecológicos.",
  },
  {
    id: "natDisaster",
    displayName: "Investigadora de desastres naturales",
    description:
      "La naturaleza crea algunos fenómenos meteorológicos muy graves y catástrofes que pueden ser devastadoras para las personas, la vida silvestre y el medio ambiente. Los investigadores de desastres naturales incluyen un amplio equipo de científicos que trabajan para prevenir y disminuir el daño de estos eventos, ayudando a salvar personas, animales y ecosistemas vulnerables. Podrían estudiar la erupción de volcanes (vulcanólogos); terremotos y tsunamis (sismólogos); tornados, tormentas de nieve y sequías (meteorólogos); o incendios forestales (ecologistas de incendios forestales).",
  },
  {
    id: "susBuilder",
    displayName: "Constructora sostenible",
    description:
      "Los constructores sostenibles y los profesionales de la construcción utilizan materiales ecológicos y prácticas de construcción para construir todo, desde casas hasta rascacielos. Esto significa usar materiales ecológicos como madera recuperada, bambú y piedra y metal reciclados, y usar diseños de construcción favorables para el medio ambiente. Colocar paneles solares en el exterior de los edificios puede servir para aprovechar el poder del sol y usar menos electricidad en el interior. La construcción de un sistema donde el agua residual de los grifos dentro de una casa se redirige a las plantas exteriores ayuda a conservar el agua. Este campo profesional no solo logra un planeta más feliz y limpio, sino que también crea hermosos lugares donde las personas pueden vivir y trabajar.",
  },
  {
    id: "teacher",
    displayName: "Docente o Especialista en educación de CTIMFD",
    description:
      "Los educadores cumplen una función fundamental en la formación de las generaciones futuras. Los docentes en las áreas de ciencia, informática y tecnología, ingeniería o matemáticas pueden trabajar con estudiantes desde preescolar hasta el nivel secundario, o incluso como profesores en una universidad. Lo que todos tienen en común es el objetivo de inspirar a otros a perseguir sus propios sueños en el campo de CTIMFD. Los profesionales de la educación también pueden desempeñarse como especialistas en planes de estudios o administradores de educación, que planifican lecciones para clases de CTIMFD en varias escuelas, supervisan programas de educación fuera de la escuela en un museo de ciencias o abogan por una mejor programación de la educación CTIMFD. También podrían trabajar con empresas y organizaciones sin fines de lucro para apoyar sus actividades de participación comunitaria de CTIMFD.",
  },
  {
    id: "sciWriter",
    displayName: "Escritora de ciencias",
    description:
      "La ciencia no solo necesita investigadores y profesionales: También necesita excelentes comunicadores. Se necesita alguien con una buena comprensión de los campos científicos, principios e investigación para informar sobre estos temas en revistas académicas, comunicados de prensa, periódicos y otros medios de comunicación. Cuando se realiza un descubrimiento científico innovador, ¿puede desglosar la información y explicarla al público? Para ser un escritor científico, se requieren habilidades de escritura, edición y comunicación, así como ser apasionado por el campo científico de su elección, desde la medicina hasta la física y la astronomía.",
  },
  {
    id: "advocate",
    displayName: "Defensora del cambio de políticas",
    description:
      "¿Le importa defender los derechos humanos? ¿Lucha contra el hambre, la pobreza y la inequidad sanitaria? ¿Protege especies en peligro de extinción? ¿Previene el calentamiento global? Algunas cuestiones sociales importantes como estas pueden mejorarse mediante cambios en las leyes o políticas, y estos cambios requieren el trabajo dedicado de defensores y activistas que desean hacer del mundo un lugar mejor. Esta carrera podría incluir la revisión de datos y el uso de análisis estadísticos para comprender un problema, o la redacción de resúmenes de políticas para audiencias gubernamentales. Dependiendo del problema social particular en el cual esté trabajando, podría requerir experiencia en cualquiera de los campos de CTIMFD.",
  },
];

export default careers;
