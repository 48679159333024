import React from "react";

const copy = {
  home: {
    header: "How will you use your unique talents to make a difference?",
    p1:
      "There are thousands of incredible jobs in science, technology, engineering, math, manufacturing, and design. You could design the next solar panel or clean energy resource, help heal injured animals, or solve seemingly impossible equations.",
    p2:
      "Take this quiz and explore how a career in STEM2D can help you shape the future.",
    cta: "Start the quiz",
  },
  quiz: {
    rankingPrompt: (
      <span>
        Choose your <span>top two</span> priorities.
      </span>
    ),
    multiPrompt: <span>Select one answer.</span>,
    counter1: "question",
    counter2: "of",
    cta: "Next",
    lastCta: "See results",
  },
  results: {
    loading: "Calculating your results",
    header: "We've got your top matches.",
    subheader:
      "Based on your unique answers, here are some exciting career possibilities for you to explore!",
    readMore: "Read more",
    hide: "Hide",
    shareText: "I'm a great match for ",
    nextStepsHeader: "Explore more from home!",
    nextStepsBody: (
      <p>
        Start your journey toward an exciting future with these fun STEM
        <sup>2</sup>D activities that use materials from around the house!
      </p>
    ),
    nextStepsCta: "Learn more",
    tryAgainPrompt: "Want to try again?",
    tryAgainCta: "Retake the quiz",
    exploreMorePrompt: "Explore more careers",
    exploreMoreCta: "See the full list",
    exploreMoreCta2: "Show my top results",
  },
  global: {
    footer: "Developed with support from Johnson & Johnson",
  },
};

export default copy;
