import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  Link,
} from "react-router-dom";

import Home from "./components/views/Home";
import Quiz from "./components/views/Quiz.js";
import LoadingResults from "./components/views/LoadingResults.js";
import Results from "./components/views/Results.js";

import LanguageSelect from "./components/elements/LanguageSelect.js";

import careersEnglish from "./config/careers.js";
import questionsEnglish from "./config/questions.js";
import copyEnglish from "./config/copy.js";
import careersSpanish from "./config/careers_esp";

import "./App.scss";

import logo from "./assets/fhi-360-logo.png";
import stem_logo from "./assets/logo.svg";
import stem_logo_esp from "./assets/logo_esp.svg";

if (!String.prototype.includes) {
  String.prototype.includes = function () {
    "use strict";
    return String.prototype.indexOf.apply(this, arguments) !== -1;
  };
}
if (!Array.prototype.findIndex) {
  Object.defineProperty(Array.prototype, "findIndex", {
    value: function (predicate) {
      // 1. Let O be ? ToObject(this value).
      if (this == null) {
        throw new TypeError('"this" is null or not defined');
      }

      var o = Object(this);

      // 2. Let len be ? ToLength(? Get(O, "length")).
      var len = o.length >>> 0;

      // 3. If IsCallable(predicate) is false, throw a TypeError exception.
      if (typeof predicate !== "function") {
        throw new TypeError("predicate must be a function");
      }

      // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
      var thisArg = arguments[1];

      // 5. Let k be 0.
      var k = 0;

      // 6. Repeat, while k < len
      while (k < len) {
        // a. Let Pk be ! ToString(k).
        // b. Let kValue be ? Get(O, Pk).
        // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
        // d. If testResult is true, return k.
        var kValue = o[k];
        if (predicate.call(thisArg, kValue, k, o)) {
          return k;
        }
        // e. Increase k by 1.
        k++;
      }

      // 7. Return -1.
      return -1;
    },
    configurable: true,
    writable: true,
  });
}

if (!Array.prototype.findIndex) {
  Object.defineProperty(Array.prototype, "findIndex", {
    value: function (predicate) {
      // 1. Let O be ? ToObject(this value).
      if (this == null) {
        throw new TypeError('"this" is null or not defined');
      }

      var o = Object(this);

      // 2. Let len be ? ToLength(? Get(O, "length")).
      var len = o.length >>> 0;

      // 3. If IsCallable(predicate) is false, throw a TypeError exception.
      if (typeof predicate !== "function") {
        throw new TypeError("predicate must be a function");
      }

      // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
      var thisArg = arguments[1];

      // 5. Let k be 0.
      var k = 0;

      // 6. Repeat, while k < len
      while (k < len) {
        // a. Let Pk be ! ToString(k).
        // b. Let kValue be ? Get(O, Pk).
        // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
        // d. If testResult is true, return k.
        var kValue = o[k];
        if (predicate.call(thisArg, kValue, k, o)) {
          return k;
        }
        // e. Increase k by 1.
        k++;
      }

      // 7. Return -1.
      return -1;
    },
    configurable: true,
    writable: true,
  });
}

function App() {
  const [careersEng, setCareersEng] = useState(careersEnglish);
  const [careersEsp, setCareersEsp] = useState(careersSpanish);

  console.log(careersEng);

  const [threshold, setThreshold] = useState(0);
  const [selections, setSelections] = useState([]);
  const [careersExpanded, setCareersExpanded] = useState([]);
  const [showAllResults, toggleAllResults] = useState(false);
  const [transitioning, setTransitioning] = useState(false);
  const [password, updatePassword] = useState("");
  const [locked, setLocked] = useState(false);
  const [loggedSource, setLoggedSource] = useState(false);
  const [path, setPath] = useState("home");
  const [careerResults, setCareerResults] = useState(careersEng);
  const [questions, setQuestions] = useState(questionsEnglish);
  const [copy, setCopy] = useState(copyEnglish);
  const [language, setLanguage] = useState("english");
  const [usedQueryLang, setUsedQueryLang] = useState(false);
  
  useEffect(() => {
    const query = window.location.search;

    if (query.includes("twitter") && loggedSource === false) {
      window.ga(function () {
        setLoggedSource(true);
        console.log("source is twitter");
        window.ga("send", "event", "InboundLink", "viaTwitterShare");
      });
    }

    if (query.includes("facebook") && loggedSource === false) {
      window.ga(function () {
        setLoggedSource(true);
        console.log("source is facebook");
        window.ga("send", "event", "InboundLink", "viaFacebookShare");
      });
    }
  }, []);
  

  const getMaxScores = () => {
    const maxScores = {};
    questions.forEach((question, i) => {
      const careerIds = {};
      question.options.forEach((option, j) => {
        option.careers.forEach((career, k) => {
          careerIds[career] = careerIds[career] + 1 || 1;
          // console.log(
          //   `found ${career} in question ${i} option ${j}, incremented count to ${careerIds[career]}`,
          // );
        });
      });
      const keys = Object.keys(careerIds);
      keys.forEach((key, j) => {
        if (question.type === "ranking") {
          maxScores[key] =
            careerIds[key] >= 2
              ? maxScores[key] + question.score * 1.5 || question.score * 1.5
              : maxScores[key] + question.score || question.score;
        } else
          maxScores[key] = maxScores[key] + question.score || question.score;
      });
    });
    // console.log(maxScores);

    return maxScores;
  };

  const scoreCareers = () => {
    // create an array of career objects cloned from career config
    // from each option of each question, tally a max score and add it as a property of the new career object
    // from user-selected option of each question (selections array), tally the actual score for each career
    // divide the actual by max score to get a percent match
    // sort the new careers array by the percent value
    // call setCareerResults with the new array as the argument
    // maybe use a timeout function to reroute to results page
    let maxScoresMap = getMaxScores();
    const scoredCareersEng = careersEng.map((career, i) => {
      return { ...career, score: 0, maxScore: maxScoresMap[career.id] };
    });
    const scoredCareersEsp = careersEsp.map((career, i) => {
      return { ...career, score: 0, maxScore: maxScoresMap[career.id] };
    });
    let highScore = 0;
    selections.map((selection, i) => {
      switch (typeof selection) {
        case "object":
          questions[i].options[selection[0]].careers.map((option) => {
            const careerMatch = scoredCareersEng.findIndex(
              (element) => element.id === option
            );
            scoredCareersEng[careerMatch].score += questions[i].score;
            scoredCareersEsp[careerMatch].score += questions[i].score;
            // console.log(`adding ${questions[i].score}pt for ${careerMatch}`);
            if (scoredCareersEng[careerMatch].score > highScore) {
              highScore = scoredCareersEng[careerMatch].score;
            }
          });
          questions[i].options[selection[1]].careers.map((option) => {
            const careerMatch = scoredCareersEng.findIndex(
              (element) => element.id === option
            );
            scoredCareersEng[careerMatch].score += questions[i].score / 2;
            scoredCareersEsp[careerMatch].score += questions[i].score / 2;

            // console.log(
            //   `adding ${questions[i].score / 2}pt for ${careerMatch}`,
            // );
            if (scoredCareersEng[careerMatch].score > highScore) {
              highScore = scoredCareersEng[careerMatch].score;
            }
          });
          break;
        default:
          questions[i].options[selection].careers.map((option) => {
            const careerMatch = scoredCareersEng.findIndex(
              (element) => element.id === option
            );
            scoredCareersEng[careerMatch].score += questions[i].score;
            scoredCareersEsp[careerMatch].score += questions[i].score;
            if (scoredCareersEng[careerMatch].score > highScore) {
              highScore = scoredCareersEng[careerMatch].score;
            }
          });
          break;
      }
    });
    const sortedCareersEng = scoredCareersEng.sort((a, b) => {
      if (b.score / b.maxScore - a.score / a.maxScore === 0)
        return a.id > b.id ? 1 : -1;
      else return b.score / b.maxScore - a.score / a.maxScore;
    });
    const sortedCareersEsp = scoredCareersEsp.sort((a, b) => {
      if (b.score / b.maxScore - a.score / a.maxScore === 0)
        return a.id > b.id ? 1 : -1;
      else return b.score / b.maxScore - a.score / a.maxScore;
    });

    setCareersEng(sortedCareersEng);
    setCareersEsp(sortedCareersEsp);
    setCareerResults(
      language === "english" ? sortedCareersEng : sortedCareersEsp
    );
    setThreshold(highScore);
    setCareersExpanded(
      careerResults.map(() => {
        return false;
      })
    );
  };
  
  
  

  const NoTransitionGroup = (
    <Switch>
      <Route path="/quiz" component={null}>
        <Quiz
          selections={selections}
          setSelections={setSelections}
          scoreCareers={scoreCareers}
          transitioning={transitioning}
          setTransitioning={setTransitioning}
          path={path}
          setPath={setPath}
          questions={questions}
          copy={copy}
        />
      </Route>
      <Route path="/loading-results">
        <LoadingResults setPath={setPath} path={path} copy={copy} />
      </Route>
      <Route path="/results" component={null}>
        <Results
          careerResults={careerResults}
          careersExpanded={careersExpanded}
          setCareersExpanded={setCareersExpanded}
          showAllResults={showAllResults}
          toggleAllResults={toggleAllResults}
          threshold={threshold}
          setSelections={setSelections}
          path={path}
          setPath={setPath}
          language={language}
          copy={copy}
        />
      </Route>
      <Route path="/index.html">
        <Home setPath={setPath} path={path} copy={copy} />
      </Route>
      <Route path="/*">
        <Redirect to="/index.html" />
      </Route>
    </Switch>
  );

  return (
    <div className={`App ${path} ${language}`}>
      <Router>
        <header className="App-header">
          <Link
            to="/index.html"
            onClick={() => {
              setSelections([]);
            }}
          >
            <img
              id="stem-logo"
              alt="STEM2D"
              src={language === "spanish" ? stem_logo_esp : stem_logo}
            />
          </Link>
        </header>
        {NoTransitionGroup}
      </Router>

      <LanguageSelect
        language={language}
        setLanguage={setLanguage}
        setCopy={setCopy}
        careersEsp={careersEsp}
        careersEng={careersEng}
        setCareerResults={setCareerResults}
        setQuestions={setQuestions}
        setUsedQueryLang={setUsedQueryLang}
        usedQueryLang={usedQueryLang}
      />
      <footer>
        <img src={logo} alt="FHI360 logo" className="fhi-logo" />
        <p className="sponsor-message">{copy.global.footer}</p>
      </footer>
    </div>
  );
}

export default App;
