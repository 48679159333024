import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import bg from "../../assets/dna.jpg";

import arrow from "../../assets/arrow_white.svg";

function Home(p) {
  useEffect(() => {
    if (p.path !== "home") p.setPath("home");
  });

  window.ga(function () {
    // console.log("ga was detected from home");
    var trackers = window.ga.getAll();
  });

  return (
    <main className="home">
      <img src={bg} className="background" alt="DNA double helix" />
      <header className="home">
        <h1>{p.copy.home.header}</h1>
      </header>
      <article className="home">
        <p>{p.copy.home.p1}</p>
        <p>{p.copy.home.p2}</p>
      </article>
      <Link to="/quiz/0">
        <button className="red start-quiz">
          <p>
            {p.copy.home.cta}{" "}
            <img alt="button arrow" className="button-arrow" src={arrow} />
          </p>
        </button>
      </Link>
    </main>
  );
}

export default Home;
