import React, { useState, useEffect } from "react";
import CountUp from "react-countup";
import { Redirect } from "react-router-dom";

function LoadingResults(p) {
  useEffect(() => {
    if (p.path !== "loading-results") p.setPath("loading-results");
  });

  const [els, setEls] = useState(<div></div>);
  const [percent, setPercent] = useState(0);
  const [rerender, setRerender] = useState(false);

  setTimeout(() => {
    if (!rerender) {
      setEls(<Redirect to={`/results`} />);
    }
  }, 5500);

  return (
    <main className="results loading-results">
      <header className="results">
        <h1>{p.copy.results.loading}</h1>
      </header>

      <CountUp start={0} end={100} duration={5.3} delay={0}>
        {({ countUpRef }) => (
          <p className="loading-counter">
            <span ref={countUpRef} />%
          </p>
        )}
      </CountUp>
      <div className="progress-holder">
        <div className="progress" />
        {els}
      </div>
    </main>
  );
}

export default LoadingResults;
