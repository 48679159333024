import dye from "../assets/dye.jpg";
import desk from "../assets/desk.jpg";
import bubbles from "../assets/bubbles.png";
import bars from "../assets/bars.png";
import carryon from "../assets/carryon.jpg";
import trophy from "../assets/trophy.jpg";
import helix from "../assets/helix.jpg";
import hex from "../assets/hex.jpg";
import spirals from "../assets/spirals.jpg";
import blocks from "../assets/blocks.jpg";
import planes from "../assets/planes.jpg";
import notes from "../assets/notes.jpg";
import balloons from "../assets/balloons.jpg";
import ring from "../assets/ring_shadow_2.jpg";

import careers from "./careers.js";

const ids = careers.map((career) => {
  return career.id;
});
// console.log(ids);

const getIds = (arr) => {
  return arr.map((val) => {
    return ids[val];
  });
};

const multiChoiceOptions = [
  getIds([
    1,
    5,
    10,
    12,
    13,
    14,
    15,
    16,
    19,
    20,
    21,
    23,
    24,
    25,
    31,
    32,
    33,
    34,
    35,
    37,
    38,
    39,
  ]),
  getIds([2, 4, 7, 8, 9, 10, 17, 18, 20, 22, 27, 28, 29, 31, 33, 37, 39]),
  getIds([2, 3, 4, 9, 10, 11, 18, 22, 23, 24, 25, 26, 27, 35, 37, 39]),
  getIds([0, 1, 2, 3, 4, 5, 14, 16, 20, 22, 30, 32, 34, 35, 37, 39]),
  getIds([4, 6, 7, 15, 18, 26, 28, 36, 37, 39]),
  getIds([2, 3, 4, 7, 9, 11, 17, 18, 22, 28, 29, 36, 37, 39]),
];

const questions = [
  {
    /* spreadsheet Question 1 */
    type: "ranking",
    prompt: "¿Cómo quiere hacer la diferencia?",
    score: 2,
    options: [
      {
        text: "Ayudar a las personas.",
        careers: getIds([
          0,
          1,
          2,
          3,
          4,
          5,
          7,
          8,
          9,
          10,
          11,
          12,
          13,
          15,
          16,
          17,
          18,
          19,
          20,
          22,
          23,
          24,
          25,
          26,
          27,
          28,
          29,
          30,
          31,
          32,
          33,
          34,
          35,
          36,
          37,
          38,
          39,
        ]),
      },
      {
        text: "Ayudar al planeta.",
        careers: getIds([
          2,
          3,
          4,
          6,
          7,
          9,
          10,
          13,
          14,
          15,
          16,
          17,
          18,
          20,
          23,
          24,
          25,
          32,
          33,
          34,
          35,
          36,
          37,
          38,
          39,
        ]),
      },
      {
        text: "Ayudar a los animales.",
        careers: getIds([
          2,
          3,
          6,
          7,
          10,
          13,
          14,
          16,
          17,
          20,
          21,
          24,
          33,
          34,
          35,
          36,
          37,
          38,
          39,
        ]),
      },
    ],
    visual: {
      color: "white vertical-cross-out",
      image: dye,
      imageClass: "bottom",
    },
  },
  {
    /* spreadsheet Question 2 */
    type: "multiChoice",
    prompt: "¿Qué aventura elegiría?",
    score: 3,
    options: [
      {
        text: "Acampar en el desierto y pasar la noche mirando las estrellas.",
        careers: multiChoiceOptions[0],
      },
      {
        text:
          "Explorar mundos increíbles en realidad virtual que dan vida a la imaginación.",
        careers: multiChoiceOptions[1],
      },
      {
        text:
          "Cruzar el puente más alto del mundo, incluso si se balancea con el viento.",
        careers: multiChoiceOptions[2],
      },
      {
        text: "Resolver acertijos y descifrar códigos en una sala de escape.",
        careers: multiChoiceOptions[3],
      },
      {
        text:
          "Construir una increíble casa en el árbol de dos pisos para pasar el rato.",
        careers: multiChoiceOptions[4],
      },
      {
        text: "Ir a un desfile de modas para ver hermosos diseños de ropa.",
        careers: multiChoiceOptions[5],
      },
    ],
    visual: {
      color: "light-blue vertical-cross chomp-out",
      image: bars,
      imageClass: "rise short",
    },
  },
  {
    /* spreadsheet Question 3 */
    type: "multiChoice",
    prompt: "¿Qué artículo le gustaría encontrar esperando en su escritorio?",
    score: 3,
    options: [
      {
        text: "Un microscopio.",
        careers: multiChoiceOptions[0],
      },
      {
        text: "Un kit para construir su propia computadora.",
        careers: multiChoiceOptions[1],
      },
      {
        text: "Un bolígrafo de impresión 3D.",
        careers: multiChoiceOptions[2],
      },
      {
        text: "Una calculadora gráfica avanzada.",
        careers: multiChoiceOptions[3],
      },
      {
        text: "Una máquina de coser.",
        careers: multiChoiceOptions[4],
      },
      {
        text: "Un conjunto de suministros de arte de lujo.",
        careers: multiChoiceOptions[5],
      },
    ],
    visual: {
      color: "yellow chomp parallax-out",
      image: desk,
      imageClass: "rise long desk",
    },
  },
  {
    /* spreadsheet Question 4 */
    type: "multiChoice",
    prompt: "En su viaje de estudios ideal, usted…",
    score: 3,
    options: [
      {
        text: "Mira gemas, insectos y fósiles en un museo de historia natural.",
        careers: multiChoiceOptions[0],
      },
      {
        text: "Pone en práctica la última tecnología en un hackathón.",
        careers: multiChoiceOptions[1],
      },
      {
        text:
          "Explora un museo de invenciones que han moldeado la vida tal como la conocemos.",
        careers: multiChoiceOptions[2],
      },
      {
        text:
          "Visita un estudio de música y aprende a ajustar los niveles de sonido para hacer que las canciones se destaquen.",
        careers: multiChoiceOptions[3],
      },
      {
        text:
          "Visita una fábrica para ver cómo se hacen los objetos, desde teléfonos inteligentes hasta aviones.",
        careers: multiChoiceOptions[4],
      },
      {
        text: "Pasea por un museo de arte moderno.",
        careers: multiChoiceOptions[5],
      },
    ],
    visual: {
      color: "purple parallax vertical-cross-out",
      image: carryon,
      imageClass: "rise short purple",
    },
  },

  {
    /* spreadsheet Question 5 */
    type: "multiChoice",
    prompt: "¿Qué concurso es más probable que gane?",
    score: 3,
    options: [
      {
        text: "Una feria de ciencias.",
        careers: multiChoiceOptions[0],
      },
      {
        text: "Un torneo de videojuegos.",
        careers: multiChoiceOptions[1],
      },
      {
        text: "Una batalla robot personalizada.",
        careers: multiChoiceOptions[2],
      },
      {
        text: "Olimpiada matemática.",
        careers: multiChoiceOptions[3],
      },
      {
        text: "Una carrera de autos de caja de jabón.",
        careers: multiChoiceOptions[4],
      },
      {
        text: "Un concurso de fotografía.",
        careers: multiChoiceOptions[5],
      },
    ],
    visual: {
      color: "pink vertical-cross chomp-out",
      image: trophy,
      imageClass: "bottom left",
    },
  },
  {
    /* spreadsheet Question 6 */
    type: "multiChoice",
    prompt: "¿Qué afirmación es más cierta para usted?",
    score: 1,
    options: [
      {
        text:
          "Me encanta ir de viaje y explorar nuevos lugares. Quiero viajar por el mundo.",
        careers: getIds([0, 8, 11, 13, 14, 16, 19, 21, 23, 26, 29, 34, 35, 38]),
      },
      {
        text:
          "Me encanta crear mi propio espacio único en casa, ya sea mi habitación, escritorio o casillero.",
        careers: [],
      },
    ],
    visual: {
      color: "white2 chomp parallax-out",
      image: helix,
      imageClass: "rise helix",
    },
  },

  {
    /* spreadsheet Question 7 */
    type: "multiChoice",
    prompt: "¿Qué tipo de proyecto escolar le gusta más?",
    score: 1,
    options: [
      {
        text:
          "Grupal: Me divierto trabajando en equipo. Me gusta cuando todos aportan sus propios talentos únicos.",
        careers: getIds([
          0,
          1,
          4,
          5,
          9,
          10,
          11,
          12,
          14,
          15,
          16,
          19,
          20,
          31,
          32,
          33,
          35,
          36,
          37,
          39,
        ]),
      },
      {
        text:
          "Individual: Puedo hacer las cosas a mi manera, y me siento orgullosa de crear algo por mi cuenta.",
        careers: [],
      },
    ],
    visual: {
      color: "light-blue2 parallax vertical-cross-out",
      image: balloons,
      imageClass: "rise long balloons",
    },
  },
  {
    /* spreadsheet Question 8 */
    type: "multiChoice",
    prompt: "¿Qué afirmación es más cierta para usted?",
    score: 1,
    options: [
      {
        text:
          "Soy aventurera y me gusta hacer cosas. Me gusta aprender a través de experimentos y actividades prácticas.",
        careers: getIds([
          2,
          4,
          6,
          10,
          11,
          12,
          13,
          14,
          15,
          16,
          18,
          19,
          20,
          21,
          24,
          26,
          27,
          29,
          33,
          34,
          35,
          36,
          37,
          38,
        ]),
      },
      {
        text:
          "Soy soñadora y pensativa. Soy buena para comprender metáforas e ideas abstractas.",
        careers: [],
      },
    ],
    visual: {
      color: "white2 vertical-cross chomp-out",
      image: hex,
      imageClass: "float",
    },
  },
  {
    /* spreadsheet Question 9 */
    type: "multiChoice",
    prompt:
      "Verdadero o falso: Es una líder natural. Le gusta tomar decisiones e inspirar a otros.",
    score: 1,
    options: [
      {
        text: "Verdadero.",
        careers: getIds([2, 3, 9, 12, 13, 19, 26, 28, 30, 31, 37, 39]),
      },
      {
        text: "Falso.",
        careers: [],
      },
    ],
    visual: {
      color: "orange chomp parallax-out",
      image: spirals,
      imageClass: "rise long spirals",
    },
  },
  {
    /* spreadsheet Question 10 */
    type: "multiChoice",
    prompt:
      "Verdadero o falso: Le gustan los hechos. Le gusta investigar, estudiar y trabajar con números.",
    score: 1,
    options: [
      {
        text: "Verdadero.",
        careers: getIds([
          0,
          1,
          2,
          5,
          8,
          9,
          12,
          13,
          14,
          15,
          16,
          18,
          19,
          20,
          21,
          22,
          25,
          26,
          27,
          30,
          31,
          32,
          33,
          34,
          35,
        ]),
      },
      {
        text: "Falso.",
        careers: [],
      },
    ],
    visual: {
      color: "pink2 parallax vertical-cross-out",
      image: blocks,
      imageClass: "rise long blocks",
    },
  },
  {
    /* spreadsheet Question 11 */
    type: "multiChoice",
    prompt:
      "Verdadero o falso: Le gustan las nuevas ideas. Disfruta encontrando formas creativas de resolver problemas.",
    score: 1,
    options: [
      {
        text: "Verdadero.",
        careers: getIds([
          1,
          2,
          3,
          4,
          6,
          7,
          8,
          9,
          10,
          11,
          17,
          18,
          22,
          23,
          24,
          28,
          29,
          35,
          36,
          37,
          38,
          39,
        ]),
      },
      {
        text: "Falso.",
        careers: [],
      },
    ],
    visual: {
      color: "light-blue vertical-cross chomp-out",
      image: planes,
      imageClass: "float spin",
    },
  },
  {
    /* spreadsheet Question 12 */
    type: "multiChoice",
    prompt:
      "Verdadero o falso: Es planificadora. Es organizada y ama las listas de verificación.",
    score: 1,
    options: [
      {
        text: "Verdadero.",
        careers: getIds([
          0,
          1,
          2,
          3,
          4,
          5,
          6,
          7,
          8,
          11,
          13,
          15,
          16,
          19,
          20,
          21,
          22,
          23,
          25,
          26,
          27,
          28,
          30,
          31,
          32,
          33,
          34,
          35,
          36,
          37,
        ]),
      },
      {
        text: "Falso.",
        careers: [],
      },
    ],
    visual: {
      color: "white3 chomp parallax-out",
      image: notes,
      imageClass: "float",
    },
  },
  {
    /* spreadsheet Question 13 */
    type: "multiChoice",
    prompt:
      "Verdadero o falso: Es espontánea. Enfrenta cada día lista para infinitas posibilidades.",
    score: 1,
    options: [
      {
        text: "Verdadero.",
        careers: getIds([
          2,
          3,
          4,
          7,
          8,
          9,
          10,
          11,
          12,
          14,
          17,
          18,
          19,
          21,
          24,
          28,
          29,
          35,
          36,
          37,
          38,
          39,
        ]),
      },
      {
        text: "Falso.",
        careers: [],
      },
    ],
    visual: {
      color: "pink2 parallax vertical-cross-out",
      image: ring,
      imageClass: "float",
    },
  },
];

export default questions;
