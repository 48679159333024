const careers = [
  {
    id: "actuary",
    displayName: "Actuary",
    description:
      "An actuary helps make sure people are protected from different risks, such as natural disaster, illness, or accident. Actuaries use math to help insurance companies give people peace of mind knowing they will be taken care of if they need help. For instance, if they get sick and have high hospital bills or if their house is damaged in a flood or fire, they’ll receive financial help to cover the costs. An actuary might also travel to different places to work with governments and help them understand and prepare for big society-level risks such as climate change, epidemics, and economic crises.",
  },
  {
    id: "oncologist",
    displayName: "Mathematical Oncologist",
    description:
      "Mathematicians can help researchers, doctors, and medical staff outsmart cancer by using math to understand the growth rate of tumors. Mathematical oncologists are an important part of the team in the field of oncology, which is the area of medicine that handles treating cancer. They play a big role in cancer research by measuring and predicting how cancerous cells will grow and travel to other parts of the body (metastasize) through modeling and simulation. Mathematical oncologists can use the data they collect to understand how cancerous cells will respond to different treatments, which can save lives.",
  },
  {
    id: "dataPro",
    displayName: "Data Professional",
    description:
      "A data professional can include a lot of different careers, like data analyst, data architect, data engineer, or data scientist. What do they all have in common? These professionals use statistics (a kind of math), to understand and solve the world’s complex problems. Organize, manage, and interpret huge amounts of data to get to the BIG picture. How can we stop a pandemic? What can we do to protect endangered species? How can farmers grow more food more efficiently to help fight hunger? You can use numbers to answer these questions and make a meaningful difference in any cause you care about.",
  },
  {
    id: "architect",
    displayName: "Architect or Civil Drafter",
    description:
      "Architects and civil drafters draw the buildings and spaces that make up our world. They design everything from houses and huge skyscrapers to parks and whole cities. You can bring beautiful spaces to life from your imagination for people to live in and enjoy. You might specialize in extreme weather conditions and design buildings that will stay standing in an earthquake or focus on sustainable architecture to make buildings energy efficient—or green—to help the planet. You could even focus on designing amazing animal habitats, a special kind of landscape architecture where you work with zoologists.",
    expertName: "Zaha Hadid",
    expertTitle: "Architect",
    expertDesc: 
      "Zaha Hadid, sometimes called the 'Queen of the Curve' for her innovative use of curves in her buildings, was a pioneer in the field of architecture. Iraqi-born Hadid first attended the American University of Beirut, Lebanon, to study mathematics before relocating to London in 1972 to enroll in the Architectural Association School of Architecture. In 1983, Hadid won a design competition for The Peak, a recreational center in Hong Kong, and became a well-known name in the architecture industry. She received the Pritzker Architecture Prize in 2004 and the Royal Institute of British Architects Gold Medal in 2016. She was the first woman to win that prestigious award in the field of architecture. Her most well-known works include the Guangzhou Opera House, the Heydar Aliyev Cultural Center, the London Aquatics Center, and the Al Janoub soccer stadium used in the Qatar World Cup in 2022.",
    expertPhoto: "/experts/architect.jpg",
    expertCredit: "Ternovoy, D. (2015). Zaha Hadid in Heydar Aliyev Cultural center in Baku nov 2013 [Photograph]. WikiCommons. https://en.wikipedia.org/wiki/Zaha_Hadid"
  },
  {
    id: "carDesigner",
    displayName: "Car designer, engineer or manufacturer",
    description:
      "With a career in the auto industry, you can bring the most exciting car innovations to life. Whether they work in the design, engineering, or manufacturing part of the process, the people who create the cars we drive don’t just make them beautiful and fun. They work hard to create smart safety features that prevent car accidents and save lives. They also build eco-friendly cars that run on electricity or clean fuels, creating cleaner air and protecting the environment from harmful greenhouse gases that cause global warming.",
    expertName: "Michelle Christensen",
    expertTitle: "Race Car Designer",
    expertDesc: 
      "Michelle Christensen is the first female exterior designer for Acura. An exterior designer defines the proportions, forms, and surfaces of a car's exterior shape. When Michelle was a child, she would spend time helping her father who had a passion for working on hot rods and muscle cars. She had an interest in drawing and fine arts while in school but didn't learn about automobile design or that it was even a career choice until she was in her second year of community college. Once joining the industry after graduating with a Bachelor of Science in Industrial Design from the Art Center College of Design in Pasadena, California, Christensen quickly rose to the top. In 2010 Christensen received Marie Claire Magazine’s Women on Top award as an up-and-coming female professional. Christensen was named the 2016 Honda NSX's lead designer where she focused on creating a high performing, exotic-looking car. One year later, she made it onto the 'Power List' of industry leaders in MotorTrend magazine. Christensen chose to pursue a career in car design because she felt car design is the ideal blend of her interests in art, automobiles, and making things for people.",
    expertPhoto: "/experts/race-car-designer.jpg",
    expertCredit: "Warren, T. (2015). Acura NSX Designer Michelle Christensen [Photograph]. Car and Driver. https://www.caranddriver.com/news/a15356691/acura-nsx-designer-michelle-christensen-on-the-influence-of-nature-and-the-67-chevelle/"
  },
  {
    id: "biostatistician",
    displayName: "Biostatistician",
    description:
      "Combining the fields of biology and statistics (a kind of math), biostatisticians look at data from medical research and analyze it to better understand how to keep people well and healthy. They often work with other researchers and scientists.  For example, NASA scientists work with biostatisticians to study how outer space and low gravity affect the human body so they can protect astronauts from harm during long space trips. Biostatisticians might also help with clinical trials to see how effective a new medicine is at treating an illness, or to test how well a vaccine protects against a dangerous virus.",
  },
  {
    id: "recycleManufacture",
    displayName: "Sustainable or Recycled Materials Manufacturer",
    description:
      "Use your hands to create everyday products out of materials that are good for the planet. When we recycle, a whole team of people are behind the scenes turning those recycled materials into new things. Sorters separate materials like plastic and glass; assemblers and fabricators turn recycled materials into new products; and facility managers make sure everything runs smoothly and safely. This team turns yesterday’s trash into tomorrow’s treasure. Other sustainable materials are also critical to manufacturing. In clothing manufacturing, materials like canvas and faux leather create beautiful fashion statements that are animal friendly. For furniture builders, reclaimed wood can be used to create gorgeous tables and dressers that are durable and environmentally friendly.",
  },
  {
    id: "printTech",
    displayName: "3D Printing Technician",
    description:
      "The exciting world of 3D printing can make a big difference. Sometimes called additive manufacturing, it uses technology to “print” 3D objects from a computer design. It’s precise, so a 3D printing technician can replicate real-world objects that look and feel like the real thing. So, what if you could print coral reef substrates to regrow underwater coral ecosystems and save endangered species? Or print low-cost building materials to rebuild a city after a natural disaster? What if you could print prosthetics, or artificial limbs, to help someone who has lost a leg walk again? 3D Printing Technicians  make this happen.",
    expertName: "Alison Mendoza",
    expertTitle: "3D Printing",
    expertDesc: 
      "Mendoza is the Senior Product Manager at GE Additive whose team is responsible for creating products that solve manufacturing challenges, improving business outcomes, and helping change the world for the better. Mendoza is a Business Development professional with extensive experience in Aviation, Aerospace MRO (maintenance, repair, and overhaul), and Additive Manufacturing (AM), and she is regarded as one of the most significant persons in 3D printing. She graduated from Embry-Riddle Aeronautical University with a Bachelor of Arts in Applied Linguistics and a Master of Business Administration with a concentration in Aviation. Mendoza spent her first few years in aviation, living in Dubai and working for Emirates Airline. When she returned to the United States, she worked with AeroTurbine for several years before leaving to join Honeywell Aerospace, where she was first officially introduced to 3D printing. In addition to using her 3D printing abilities to design and manufacture specialized parts for airplanes, she enjoys sharing stories that may go unnoticed by the public regarding the various uses for 3D printing. For example, 3D printing in assistive technology can be used to customize mobility aids such as wheelchairs. This is just one example that demonstrates the benefits of using AM for quick and effective customization to improve one's quality of life.",
    expertPhoto: "/experts/3d-printing.jpg",
    expertCredit: "Toure, N. (2019). Alison Mendoza [Photograph]. Women in 3D Printing. https://womenin3dprinting.com/alison-mendoza-for-years-weve-been-seeking-the-obvious-wins-as-i-like-to-say-its-time-to-uncover-the-overlooked/"
  },
  {
    id: "hacker",
    displayName: "Ethical Hacker",
    description:
      "Ethical hackers are computer scientists and programmers who can help a company or organization tell if they are vulnerable to hacking—when criminals gain access to computer networks and systems to steal digital information, money, or their employees’ identities. Ethical hackers might have the same skills as the bad guys, but they choose to use them for good. They work with the company to find weak spots where hacking criminals can get in, and they help increase security to protect them from that threat. Ethical hackers might even travel around to different companies to provide their services.",
    expertName: "Farah Hawa",
    expertTitle: "Ethical Hacker",
    expertDesc: 
      "Farah Hawa has several titles, including application security engineer, bug bounty hunter, and content creator. For several years she’s been spreading awareness in the world of information security, or infosec, all over social media platforms. Farah went to college to learn how to be a writer. She soon realized, however, that journalism was not her passion, so she set out to discover what was. That's when she came across info security. Farah discovered her first software defect or “bug” and received a $100 reward. She decided to share her experience by posting about it on one of her social platforms. Her post generated a lot of interest from other beginners, who had numerous questions for her. Farah identified a market opportunity for content targeting novice hackers, recognizing the need for guidance from someone who is also a beginner in the field. Farah used her expertise in information security and media to establish her own YouTube channel. She boasts a substantial following of over 35,000 subscribers on YouTube and over 40,000 followers on Instagram.",
    expertPhoto: "/experts/ethical-hacker.jpg",
    expertCredit: "[YouTube @FarahHawa]. (2020, May 23). Farah Hawa [Video]. YouTube. https://www.youtube.com/@FarahHawa/about"
  },
  {
    id: "mechDrafter",
    displayName: "Mechanical Drafter",
    description:
      "Mechanical drafters draw detailed diagrams of machines and mechanical devices and help engineers, architects, and builders bring them to life. Based on your interest, there are many ways to support the work in this field. You could be part of the team creating new medical technology that saves lives in hospitals, designing new and efficient transit systems to provide clean public transportation that helps the environment, or drawing complex robots that can be sent into dangerous environments instead of people risking their lives.",
  },
  {
    id: "biomed",
    displayName: "Biomedical Nanotechnology Engineer",
    description:
      "Nanotechnology is a revolutionary engineering field that is changing the face of science, technology, the environment, and health. Nanotechnology engineers develop and work with materials on a very tiny scale – think molecular level – to solve problems and make life easier. A biomedical specialist in this field might use tiny particles to find and kill cancer cells, or use biomaterials in tissues engineering to replace tissues and organs that are damaged by disease or injury. Often, this career includes research in a lab, conducting experiments and using advanced technology. These amazing research discoveries are changing the field of medicine to help people all over the world.",
  },
  {
    id: "elecEngineer",
    displayName: "Electrical Engineer",
    description:
      "Electrical engineers are creative problem solvers, using math in unusual ways to create efficient energy systems. Energy is everywhere, so electrical engineers can have a career anywhere in the world. With many sub-fields, your specific interests can shine in this field. Electrical Engineers help  [DP1] design electric cars to decrease gas and poisonous air pollution. Optical engineers work with fiber optics to provide internet access for across the world. Computer engineers build faster computers that use less energy. Newcomers to the field are constantly helping to transform the industry. Diverse viewpoints bring creativity, so expect to work with teams of other engineers trying to make the world a better place.",
  },
  {
    id: "sportsHealth",
    displayName: "Sports Medicine Healthcare Provider",
    description:
      "From athletic trainers to sports medicine physicians, healthcare providers in sports medicine have special training to treat injuries related to athletics. They might help professional athletes, people with physically demanding jobs, kids and teens who play recreational sports, and other adults who get hurt while exercising. These healthcare professionals could specialize in muscle injuries, orthopedic surgery, concussions (brain injuries), and athletic training. Whatever the focus, they all work to help people heal after high-impact injuries or reach the peak of their fitness abilities.",
  },
  {
    id: "conservationSci",
    displayName: "Conservation Scientist",
    description:
      "Conservation scientists care about safeguarding the world’s natural resources. They often work outdoors—sometimes traveling to forests, public parks, and remote areas—to help protect water, land, wildlife habitats, or plants. A conservationist may work with ranchers to keep animals from over-grazing and threatening grasslands; advise farmers on the best ways to preserve the water they use for their crops; or study how to protect coral reefs and all the marine animals that live in them. Their passion for nature benefits everyone—including the animals and people who live in and enjoy it!",
  },
  {
    id: "wildBio",
    displayName: "Wildlife Biologist",
    description:
      "As a wildlife biologist, you can study the biology, behavior, and habitats of your favorite wild animals. Explore the jungles of Sri Lanka to study how to protect native leopards from extinction, or travel to the plains of the Serengeti to help tag and relocate wild rhinos, giraffes, and elephants and save vulnerable ecosystems. Investigate the impact of commercial projects on the local wildlife populations and provide local governments with recommendations to protect land and water habitats. Wildlife biologists work on teams of scientists both in nature and in a lab, and the work can include scientific experiments and traveling to remote locations around the world.",
    expertName: "Dame Jane Goodall",
    expertTitle: "Wildlife Biologist",
    expertDesc: 
      "After 60 years of researching the social and family dynamics of wild chimps, Dr. Goodall is widely regarded as the world's top expert on chimps. Goodall, who had a lifelong interest in animal behavior, dropped out of school at the age of 18. She began her career in wildlife biology as an assistant to the renowned anthropologist Louis Leakey. He introduced her to chimp research at Gombe Stream National Park in eastern Africa. Although Goodall dropped out of school, her passion for life-long learning and practical experiences ultimately led to a PhD later in life.  In 1965, the University of Cambridge awarded Goodall a Ph.D. in ethology; she was one of just a few candidates to acquire a Ph.D. without previously earning a bachelor degree. She was crucial in understanding the cognition and thinking processes of wild chimps. She cofounded the Jane Goodall Institute for Wildlife Research, Education, and Conservation in California in 1977; the institution eventually relocated to the Washington, D.C., region. She has founded several other organizations including the Jane Goodall's Roots & Shoots (1991) program, which is a youth service program where participants identify and address problems in their communities, while becoming the compassionate citizens that our planet needs. In 2002, Goodall was named a 'Messenger of Peace' by the United Nations. In 2004, she became a Dame Commander of the Order of the British Empire, an honor bestowed upon her by Prince Charles. Additionally, Dr. Goodall was honored for her global and visionary leadership at Montana State University in April of 2008.",
    expertPhoto: "/experts/wildlife-biologist.jpg",
    expertCredit: "US Department of State (2015). Jane Goodall [Photograph]. Deputy Secretary Higginbottom Poses for a Photo With Dr. Jane Goodall and the State Department's Global Health Diplomacy Director Jordan in Washington. https://commons.wikimedia.org/wiki/File:Jane_Goodall_2015.jpg"
  },
  {
    id: "lifeScience",
    displayName: "Life Sciences Manufacturing Specialist",
    description:
      "Imagine researchers have just come up with a brand-new vaccine for a virus that is infecting people around the world. Next, this vaccine must be mass-produced—and quickly—so that people can receive it as soon as possible. Manufacturing specialists are some of the people responsible for getting vaccines, as well as other medications for people and animals, from a researcher’s lab to your local pharmacy or vet. These specialists can support the production of the vaccine in batches by making sure the manufacturing equipment is working properly, products are moving efficiently through the production line, and safety guidelines are followed.",
  },
  {
    id: "envScience",
    displayName: "Environmental Scientist",
    description:
      "Apply your love of science to solve environmental problems like pollution, helping the planet as well as the people and animals who live on it. Environmental scientists often focus on ways to lower pollution in air, water, and soil. They can include specialists who collect samples of water and soil to test for toxic chemicals or radiation; researchers in the lab who perform the tests; and inquisitive go-getters who travel to investigate the sources of pollution. Environmental scientists often work with a team of people who are all dedicated to achieving the same goal, like making the air that we breathe cleaner or preventing climate change.",
    expertName: "Isabel Escobar",
    expertTitle: "Environmental Engineer",
    expertDesc: 
      "Escobar has spent over 20 years researching and developing chemical membrane materials for water purification and reuse, and assessing the biological stability of distribution networks. Escobar studied at Instituto Pio XI in Rio de Janeiro, Brazil and attended the University of Central Florida where she earned all three of her degrees in environmental engineering (Doctor of Philosophy, Master of Science, and Bachelor of Science). Escobar's work centers on enhancing polymeric membrane materials to use in water treatment and reuse processes. Membrane technology is used in water treatment to separate contaminants from water based on properties such as size or electrical charge. Common membrane processes include microfiltration, ultrafiltration, nanofiltration, reverse osmosis, and electrodialysis. These processes are used to clean surface water, groundwater, and wastewater to produce water for industry and for drinking. In 2009, Isabel Escobar was awarded the Northwest Ohio YWCA Milestone Award for Education, and the Toledo 20 Under 40 Leadership award. In 2010, Escobar was named Professor of Chemical and Environmental Engineering at the University of Toledo and interim assistant dean for Research Development and Outreach for the College of Engineering. Since 2015, she has held the position of professor in the University of Kentucky Department of Chemical and Materials Engineering in addition to serving as a Society of Hispanic Professional Engineers' advisor at the university.",
    expertPhoto: "/experts/environmental-engineer.jpg",
    expertCredit: "Corwin, B. (2022). Isabel Escobar, a professor of Chemical and Materials Engineering, conducts water and air filtration research. [Photograph]. UKNOW University of Kentucky News. https://uknow.uky.edu/research/women-making-history-series-kicks-isabel-escobar-behind-blue"
  },
  {
    id: "webDesign",
    displayName: "Creative Designer or Web Developer",
    description:
      "Commercial companies and non-profit organizations doing meaningful work need talented artists to tell their stories. Use your creative skills and imagination to make beautiful visuals and amazing websites for the cause you care about. Design imaginative ads that encourage people to recycle and conserve water. Edit stunning photos of adoptable dogs and cats to help your local pet rescue find their forever homes. Build websites for human rights organizations that make it easy for people to support their causes. You could work on a team or be your own boss as a freelance designer helping your clients make a difference in the world.",
  },
  {
    id: "robotEngineer",
    displayName: "Robotics Engineer or Technician",
    description:
      "Robotics engineers design and program robots, while robotics technicians focus on testing, operating, maintaining, and repairing them. Both use innovative technology and engineering to create robots with artificial intelligence that can protect the planet and save people’s lives. Today, fire-fighting robots that can withstand high heat and smoke are helping first responders put out flames; drones with high-resolution cameras are helping search and rescue teams quickly find people who need saving; and tons of advanced bots are helping the planet by cleaning trash and pollutants from the ocean, planting trees, harvesting solar energy, and making clean transportation possible. What will you help them do tomorrow?",
    expertName: "Melonee Wise",
    expertTitle: "Robotics Engineer",
    expertDesc: 
      "Wise's passion for robots can be traced back to her early years. At a very young age, Wise developed a plotter from Legos, which uses automated pens to make line drawings in the form of continuous lines. Wise's decision to major in mechanical engineering at the University of Illinois is not surprising given her childhood interests. Wise spent her summers interning at many companies including Alcoa, DaimlerChrysler, and Honeywell Aerospace, which provided her with extensive experience in a variety of fields. In time, Wise became the manager of robot development at Willow Garage. There, Wise oversaw a team of engineers responsible for creating cutting-edge hardware and software for robots including ROS, the PR2, and the TurtleBot. From there she became one of the co-founders of Unbounded Robotics and eventually Fetch Robotics. In 2021, Zebra Technologies acquired Fetch Robotics and Wise currently serves as the Vice President of Robotics Automation at Zebra Technologies. Wise is active in the robotics community as a member of the A3 Robotics Board and the MHI Roundtable Advisory Committee. Wise is one of eight CEOs revolutionizing the way we work, according to Business Insider, and she has been honored with the MIT Technology Review's TR35 award (for innovators under 35), as well as inclusion on the Silicon Valley Business Journal's Women of Influence and 40 Under 40 lists. In 2022, she also won the Engelberger Award, sometimes called the 'Nobel Prize of Robotics.'",
    expertPhoto: "/experts/robotics-engineeer.jpg",
    expertCredit: "Hof, R. (2015). Fetch Robotics CEO Melonee Wise with namesake robot [Photograph]. Forbes. https://www.forbes.com/sites/roberthof/2015/08/21/in-conversation-with-fetch-robotics-ceo-melonee-wise-here-comes-wall-e-for-the-warehouse/?sh=488b006a1436"
  },
  {
    id: "healthSpecialist",
    displayName: "Healthcare Specialist",
    description:
      "Healthcare is all about providing care to patients to improve their health and well-being. It includes preventing, diagnosing, and treating all kinds of diseases and injuries. Not surprisingly, there are a variety of different career options in this field. Nurses, doctors, physicians’ assistants, phlebotomists, immunologists, epidemiologists, and dieticians all work together to improve people’s health. Many charitable aid and relief organizations also offer the opportunity to provide healthcare around the globe. Healthcare jobs are tremendously rewarding. No matter what role you play on the team, you could contribute to saving lives!",
  },
  {
    id: "scienceTech",
    displayName: "Science Technician",
    description:
      "Science technicians work in fields ranging from animal science to forensic science to food science. They use the scientific method to conduct experiments and research in their field of choice. For example, chemical technicians might experiment and test different products to ensure they do not harm people or the environment. Nuclear technicians monitor radiation levels to make sure there are no radiation leaks, testing air and water for contamination and enacting safety procedures if radiation is found. Remote sensing technicians use advanced optical tools like satellite and infrared imaging to examine environmental impacts and recommend changes to government officials.",
  },
  {
    id: "vet",
    displayName: "Veterinarian or Wildlife Rehabilitator",
    description:
      "Use veterinary medicine to heal our furry, scaled, or feathered friends—from beloved pets to wild animals living in exotic ecosystems around the world. Use your passion for helping animals to care for people’s dogs, cats, and birds at a local vet hospital; help deliver foals (baby horses) at nearby farms; or even treat rescued elephants at a wildlife refuge in Thailand.",
    expertName: "Cindy Otto",
    expertTitle: "Veterinarian",
    expertDesc: 
      "Dr. Cindy Otto serves as the founding director of the Penn Vet Working Dog Center, which is located at the University of Pennsylvania School of Veterinary Medicine. The Penn Vet Working Dog Center focuses on education, research, and data collection to enhance the well-being of search and rescue dogs. These canines are taught to detect bombs and narcotics, to rescue victims of disasters or accidents, and to identify medical illnesses such as cancer and diabetes. Otto quickly realized there was an absence of a veterinary support system specifically designed for search and rescue dogs, which prompted her to open the center in 2012. Otto was a member of the FEMA Task Force that responded to 9/11, providing veterinary care for the search and rescue canines who served at Ground Zero. Additionally, Otto and her colleagues at the center also worked on a COVID-19 detection investigation using dogs to sniff out the virus.",
    expertPhoto: "/experts/veterinarian.jpg",
    expertCredit: "(2016). Dr. Cindy Otto Honored with Dogs’ Best Friend Award [Photograph]. The Canine Chronicle. https://caninechronicle.com/breaking-news/dr-cindy-otto-honored-with-dogs-best-friend-award/"
  },
  {
    id: "softwareDev",
    displayName: "Applications Engineer or Software Developer",
    description:
      "Use human-centered design to create smartphone apps or computer programs that help people. These tech leaders create innovative programs that can change people’s lives. Some examples include collision avoidance technology that gives a warning to drivers if they are at risk for a car accident; accessible technology that assists people with disabilities; or apps that promote mental health in teens. With a career in this field, there are many ways you can use ground-breaking technology to make a difference in the world.",
  },
  {
    id: "envEngineer",
    displayName: "Environmental, Energy, or Water Engineer",
    description:
      "There are many different careers in the field of engineering that are focused on providing people with clean air, energy, and water to improve their lives. Environmental engineers work on preventing and controlling dangers such as pollution, unsafe drinking water, climate change, or hazardous waste. They might perform tests and make equipment to measure and reduce air pollution; design powerful wind turbines to generate sustainable energy; or build structures that provide clean drinking water to remote communities around the world. All these careers use civil and mechanical engineering concepts and technologies to solve global problems, making people and the planet healthier.",
  },
  {
    id: "urbGrower",
    displayName: "Urban Grower",
    description:
      "Urban growers work in agriculture in a way you might not expect. Rather than growing fruits and vegetables on rural farms, they focus on growing them on rooftop gardens in big cities. Urban growers not only reduce carbon dioxide emissions because food doesn’t need to be delivered from far away, they also improve air quality in cities by growing plants that produce oxygen. All this while providing healthy, nutritious food to people in their community. You might have a love for science and a green thumb, too!",
  },
  {
    id: "foodChemist",
    displayName: "Food or Medicine Chemist",
    description:
      "Maybe you have conducted chemistry experiments at school or at home with acids, fizzy liquids, crystals, or slime. Did you know that chemistry is also the science behind the food we eat every day and the medicine we take when we’re sick? Flavor technologists conduct experiments to create new flavors for food and drinks, often working to make nutritious meals delicious to encourage people to eat healthy food. Food chemists might also work to increase the shelf life of food, protect against food-borne diseases, or create new foods like plant-based alternatives to meat, which may be better for the environment. Medicinal chemists develop the chemical compounds that are in the pain reliever you take when you have a headache, as well as life-saving medicines that help people all over the world.",
  },
  {
    id: "productSafety",
    displayName: "Product Safety Engineer",
    description:
      "Product safety engineers know the best way to get things done efficiently and safely. They use these skills to test new and inventive commercial products to ensure their safety, from a child’s crib to a nuclear reactor at an energy power plant. Take on a leadership role as you test products to identify dangerous problems with the design or the materials and make recommendations for how to improve safety. In this career, you might even travel from factory to factory to check safety standards. Whether you’re testing the choking hazard of a new toy or ensuring a new cleaning solution doesn’t mix with household chemicals to create dangerous reactions, you can help keep millions of people safe from harm!",
  },
  {
    id: "laserTech",
    displayName: "Laser Technician or Engineer",
    description:
      "Lasers are more than just science fiction. Today, they’re responsible for everything from corrective eye surgery to laser imaging. As a laser engineer, you can develop advanced systems that can cut through diamond and other rough material, or you might assist in the design of fiber optics that provide high-speed Internet. Lasers are very precise, so an attention to detail comes in handy for the technicians operating them. This technology has led to massive jumps in surgical fields, where you can help correct someone’s eyesight so they can see clearly for the first time or assist in reconstructive surgery to grow skin or bones for people who have gone through tragic accidents.",
  },
  {
    id: "commercialDesign",
    displayName: "Commercial and Industrial Designer",
    description:
      "Do you have a flair for design? Do you sketch beautiful fashion projects or redecorate your bedroom in your spare time? Commercial and industrial designers combine beauty and functionality by creating products that make life easier and more enjoyable. Fashion designers work on colorful clothes, jewelry, and accessories; interior designers create beautiful spaces; and commercial designers make stylish everyday items like wireless headphones or trendy reusable water bottles. You can bring your imagination to life in important, real-world applications.",
  },
  {
    id: "scanner",
    displayName: "3D Scanner",
    description: `3D scanners use technology to scan real-world objects and recreate them digitally. You could use drones to capture detailed scans of natural environments to build video game worlds, or scan actors, props, and sets for spectacular special effects in movies. You could even travel to amazing historic sites to create digital backups of ancient landmarks. Incredible 3D scans of Notre Dame Cathedral in Paris will make it possible to rebuild the nearly 1,000-year-old structure to all its former glory after it was almost destroyed by a terrible fire in 2019.`,
  },
  {
    id: "sportStat",
    displayName: "Sports Statistician",
    description:
      "Sports statisticians use math to predict how well players and teams will perform in their sport. They’re great with numbers, collecting and organizing large sets of data, and understanding what it says about real-world athletes and their performances. This work takes abstract thinking and being able to look at the big picture. They build computer programs and use mathematical models to show how individual moments, like one player scoring, will affect the outcome of the game. It’s a great career if you’re good at math and love sports.",
  },
  {
    id: "patientSafety",
    displayName: "Patient Safety Officer",
    description:
      "If you’ve ever been in a healthcare facility like a hospital or doctor’s office, you know they can be very busy. Lots of moving parts are necessary to keep these places functioning smoothly, and collaboration between doctors, nurses, and other staff is key. Patient safety officers work with healthcare providers and other building staff to make their facilities as safe as possible for patients. They may create a training program to teach doctors and nurses how to prevent the spread of dangerous bacteria between patients, or analyze accidents to find out if staff made an error and prevent it from happening again. Patient safety officers read the latest research to ensure patient safety and welfare.",
  },
  {
    id: "opticalPhys",
    displayName: "Optical Physicist",
    description:
      "Optical physicists work in real-world and abstract spaces. They may ask theoretical questions like, “How do the properties of light interact with matter?” and use these questions to advance the field of optics research. Or they may work closely with engineers and ask more practical questions like, “How can lasers be used to improve people’s eyesight?” As high-level, mathematically minded professionals, optical physicists provide expertise in a variety of industries, ranging from the development of medical technologies to strengthening missile detection systems. ",
  },
  {
    id: "industrialEco",
    displayName: "Industrial Ecologist",
    description:
      "Ecology is a branch of biology that deals with how organisms interact with each other and with their environment. In other words, it studies how different species live together and how they affect the world around them—from tiny bacteria to the African bush elephant. Industrial ecologists take concepts from ecology and use them to solve complex human problems, like pollution. For example, in the same way a suckerfish attaches to a shark and lives off its waste, a biofuel company can work with a restaurant chain to convert old kitchen grease into cleaner fuel for cars, so it doesn’t end up down the drain. This career takes abstract thinking skills and creative problem-solving, and the results can make a big impact on the planet.",
  },
  {
    id: "microbio",
    displayName: "Microbiologist",
    description:
      "Microbiologists may study tiny organisms, but the opportunities in this field are anything but small! Equipped with savvy teammates and plenty of laboratory equipment, when you enter the field of microbiology you will be diving deep into the world of bacteria, algae, fungi, parasites, and even viruses. There are many focus areas within microbiology ranging from understanding bacterial growth in pipes to developing vaccines for disease outbreaks. You may want to specialize in a field like bacteriology or parasitology, work in a public health laboratory to deal with a range of different organisms that cause disease, or research how components of plant cell walls could be used to create more environmentally friendly biofuels.",
    expertName: "Ruth Ella Moore",
    expertTitle: "Microbiologist",
    expertDesc: 
      "Blood analysis, tuberculosis, tooth decay, and gut micro-organisms were all areas of study for bacteriologist Ruth Ella Moore, Ph.D. In 1933, she became the first Black woman to acquire a doctorate in the natural sciences after defending her dissertation on tuberculosis at Ohio State University. While a treatment for tuberculosis was still more than a decade away, Moore's efforts helped lead scientists to developing a cure for the disease, which was the second leading cause of death at the time. Moore joined the faculty at Howard University as an assistant professor after completing her Ph.D. in bacteriology, eventually rising to the position of department head. Moore's promotion to department head at Howard marked a first for female faculty members. Her studies on the blood types of African-Americans and, later, the susceptibilities of various gut microbes to antibiotics, have had far-reaching effects on public health. Moore was the first African-American woman to be accepted into the American Society for Microbiology, among other scientific organizations she joined during her career. Near the time of her retirement from Howard University in 1973, Moore also received honorary degrees from Oberlin College and Gettysburg College.",
    expertPhoto: "/experts/microbiologist.jpg",
    expertCredit: "Diastello, H. (2023). Ruth Ella Moore: Overcoming Racism and Misogyny to Pursue Her Passion [Photograph]. TheClarion. https://www.sinclairclarion.com/home/opinion/my-voice/2023/03/16/ruth-ella-moore-overcoming-racism-and-misogyny-to-pursue-her-passion/"
  },
  {
    id: "natDisaster",
    displayName: "Natural Disaster Researcher",
    description:
      "Nature creates some seriously scary weather events and catastrophes that can be devastating for people, wildlife, and the environment. Natural disaster researchers include a broad team of scientists who work to prevent and lessen the damage of these events, helping to save people, animals, and vulnerable ecosystems. They might study the eruption of volcanoes as volcanologists; earthquakes and tsunamis as seismologists; tornadoes, blizzards, and droughts as meteorologists; or forest fires as wildfire ecologists.",
  },
  {
    id: "susBuilder",
    displayName: "Sustainable Builder",
    description:
      "Sustainable builders and construction professionals use green materials and building practices to construct everything from houses to skyscrapers. This means using eco-friendly materials like reclaimed wood, bamboo, and recycled stone and metal, and using building designs that are environmentally friendly. Putting solar panels on the outside of buildings can harness the power of the sun so you use less electricity inside. Building a system where wastewater from faucets inside a house is rerouted to the plants outside helps conserve water. This career field not only makes the planet happier and cleaner; it creates beautiful places for people to live and work.",
  },
  {
    id: "teacher",
    displayName: "STEM Teacher or Education Specialist",
    description:
      "Educators play a critical role in shaping future generations. Teachers in the subjects of science, computers and technology, engineering, or math can work with students from preschool through high school or secondary school, or even as professors at a university. What they all have in common is a goal of inspiring others to pursue their own dreams in STEM. Education professionals might also include roles like a curriculum specialist or an education administrator, who plan lessons for STEM classes across multiple schools, oversee out-of-school education programs at a science museum, or advocate for improved STEM education programming. They might also work with companies and nonprofit organizations to support their STEM community engagement activities.",
  },
  {
    id: "sciWriter",
    displayName: "Science Writer",
    description:
      "Science doesn’t just need researchers and practitioners: It needs excellent communicators, too. It takes someone with a good understanding of scientific fields, principles, and research to report on these topics in academic journals, press releases, newspapers and other media outlets. When a groundbreaking scientific discovery is made, can you break down the information and explain it to the public? To be a science writer takes strong writing, editing, and communication skills, as well as a passion for the scientific field of your choice—from medicine to physics to astronomy.",
  },
  {
    id: "advocate",
    displayName: "Policy Change Advocate",
    description:
      "Do you care about defending human rights? Fighting hunger, poverty, and healthcare inequity? Protecting endangered species? Preventing global warming? Important social issues like these can be improved through changes in laws or policy, and these changes require the dedicated work of advocates and activists who want to make the world a better place. This career might include reviewing data and using statistical analysis to understand an issue, or writing policy briefs for government hearings. Depending on the particular social issue you work to address, it can require expertise in any of the STEM2D fields.",
    expertName: "Peggy Rajski",
    expertTitle: "Policy Change Advocate",
    expertDesc: 
      "Peggy Rajski is the founder of The Trevor Project and interim CEO. Rajski is an Oscar-winning filmmaker who won the Academy Award for Best Live Action Short for directing the short film Trevor (1994) highlighting the obstacles faced by LGBTQ youth. When The Trevor Project was founded in 1998, the general public lacked exposure to and understanding of LGBTQ identities, and media portrayals of the LGBTQ community were often offensive or nonexistent. The group's mission is to reduce the rate of LGBTQ youth suicide by implementing free, anonymous crisis intervention and suicide prevention programs on online communities visited by young people. The Trevor Project is the most prominent organization fighting for the mental health of LGBTQ youth through legislation, litigation, and public education. These activities include fighting for suicide prevention and intervention programs at the national, state, and local levels to address the underlying causes of their heightened vulnerability to suicide. In addition to providing innovative teaching, research, and advocacy initiatives, they manage TrevorSpace, the largest safe space social networking site for LGBTQ youth around the globe.",
    expertPhoto: "/experts/policy-change-advocate.jpg",
    expertCredit: "Colin, P. (2020). LMU Dean Peggy Rajski [Photograph]. Deadline. https://deadline.com/2020/05/los-angeles-film-schools-coronavirus-shutdown-changes-curriculum-hollywood-1202915930/"
  },
];

export default careers;
