import dye from '../assets/dye.jpg';
import desk from '../assets/desk.jpg';
import bubbles from '../assets/bubbles.png';
import bars from '../assets/bars.png';
import carryon from '../assets/carryon.jpg';
import trophy from '../assets/trophy.jpg';
import helix from '../assets/helix.jpg';
import hex from '../assets/hex.jpg';
import spirals from '../assets/spirals.jpg';
import blocks from '../assets/blocks.jpg';
import planes from '../assets/planes.jpg';
import notes from '../assets/notes.jpg';
import balloons from '../assets/balloons.jpg';
import ring from '../assets/ring_shadow_2.jpg';

import careers from './careers.js';

const ids = careers.map((career) => {
  return career.id;
});
// console.log(ids);

const getIds = (arr) => {
  return arr.map((val) => {
    return ids[val];
  });
};

const multiChoiceOptions = [
  getIds([
    1,
    5,
    10,
    12,
    13,
    14,
    15,
    16,
    19,
    20,
    21,
    23,
    24,
    25,
    31,
    32,
    33,
    34,
    35,
    37,
    38,
    39,
  ]),
  getIds([2, 4, 7, 8, 9, 10, 17, 18, 20, 22, 27, 28, 29, 31, 33, 37, 39]),
  getIds([2, 3, 4, 9, 10, 11, 18, 22, 23, 24, 25, 26, 27, 35, 37, 39]),
  getIds([0, 1, 2, 3, 4, 5, 14, 16, 20, 22, 30, 32, 34, 35, 37, 39]),
  getIds([4, 6, 7, 15, 18, 26, 28, 36, 37, 39]),
  getIds([2, 3, 4, 7, 9, 11, 17, 18, 22, 28, 29, 36, 37, 39]),
];

const questions = [
  {
    /* spreadsheet Question 1 */
    type: 'ranking',
    prompt: 'How do you want to make a difference?',
    score: 2,
    options: [
      {
        text: 'By helping people',
        careers: getIds([
          0,
          1,
          2,
          3,
          4,
          5,
          7,
          8,
          9,
          10,
          11,
          12,
          13,
          15,
          16,
          17,
          18,
          19,
          20,
          22,
          23,
          24,
          25,
          26,
          27,
          28,
          29,
          30,
          31,
          32,
          33,
          34,
          35,
          36,
          37,
          38,
          39,
        ]),
      },
      {
        text: 'By helping the planet',
        careers: getIds([
          2,
          3,
          4,
          6,
          7,
          9,
          10,
          13,
          14,
          15,
          16,
          17,
          18,
          20,
          23,
          24,
          25,
          32,
          33,
          34,
          35,
          36,
          37,
          38,
          39,
        ]),
      },
      {
        text: 'By helping animals',
        careers: getIds([
          2,
          3,
          6,
          7,
          10,
          13,
          14,
          16,
          17,
          20,
          21,
          24,
          33,
          34,
          35,
          36,
          37,
          38,
          39,
        ]),
      },
    ],
    visual: {
      color: 'white vertical-cross-out',
      image: dye,
      imageClass: 'bottom',
    },
  },
  {
    /* spreadsheet Question 2 */
    type: 'multiChoice',
    prompt: 'Which adventure would you choose?',
    score: 3,
    options: [
      {
        text: 'Camping in the wilderness and spending the night stargazing',
        careers: multiChoiceOptions[0],
      },
      {
        text:
          'Exploring incredible worlds in virtual reality that bring imagination to life',
        careers: multiChoiceOptions[1],
      },
      {
        text:
          'Crossing the tallest bridge in the world, even if it sways in the wind',
        careers: multiChoiceOptions[2],
      },
      {
        text: 'Solving puzzles and deciphering codes in an escape room',
        careers: multiChoiceOptions[3],
      },
      {
        text: 'Building an amazing, two-story tree house to hang out in',
        careers: multiChoiceOptions[4],
      },
      {
        text: 'Going to a fashion show to see beautiful clothing designs',
        careers: multiChoiceOptions[5],
      },
    ],
    visual: {
      color: 'light-blue vertical-cross chomp-out',
      image: bars,
      imageClass: 'rise short',
    },
  },
  {
    /* spreadsheet Question 3 */
    type: 'multiChoice',
    prompt: 'What item would you want to find waiting on your desk?',
    score: 3,
    options: [
      {
        text: 'A microscope',
        careers: multiChoiceOptions[0],
      },
      {
        text: 'A build-your-own-computer kit',
        careers: multiChoiceOptions[1],
      },
      {
        text: 'A 3D printing pen',
        careers: multiChoiceOptions[2],
      },
      {
        text: 'An advanced graphing calculator',
        careers: multiChoiceOptions[3],
      },
      {
        text: 'A sewing machine',
        careers: multiChoiceOptions[4],
      },
      {
        text: 'A deluxe art supply set',
        careers: multiChoiceOptions[5],
      },
    ],
    visual: {
      color: 'yellow chomp parallax-out',
      image: desk,
      imageClass: 'rise long desk',
    },
  },
  {
    /* spreadsheet Question 4 */
    type: 'multiChoice',
    prompt: 'On your ideal field trip, you would…',
    score: 3,
    options: [
      {
        text: 'Look at gems, insects, and fossils at a natural history museum.',
        careers: multiChoiceOptions[0],
      },
      {
        text: 'Go hands-on with the latest tech at a hackathon.',
        careers: multiChoiceOptions[1],
      },
      {
        text:
          'Explore a museum of inventions that have shaped life as we know it.',
        careers: multiChoiceOptions[2],
      },
      {
        text:
          'Visit a music studio and learn how to adjust sound levels to make songs pop.',
        careers: multiChoiceOptions[3],
      },
      {
        text:
          'Tour a factory to see how things are made, from smart phones to airplanes.',
        careers: multiChoiceOptions[4],
      },
      {
        text: 'Wander around a modern art museum.',
        careers: multiChoiceOptions[5],
      },
    ],
    visual: {
      color: 'purple parallax vertical-cross-out',
      image: carryon,
      imageClass: 'rise short purple',
    },
  },

  {
    /* spreadsheet Question 5 */
    type: 'multiChoice',
    prompt: 'Which contest are you most likely to win?',
    score: 3,
    options: [
      {
        text: 'A science fair',
        careers: multiChoiceOptions[0],
      },
      {
        text: 'A video game tournament',
        careers: multiChoiceOptions[1],
      },
      {
        text: 'A custom-built robot battle',
        careers: multiChoiceOptions[2],
      },
      {
        text: 'A math Olympiad',
        careers: multiChoiceOptions[3],
      },
      {
        text: 'A soapbox car race',
        careers: multiChoiceOptions[4],
      },
      {
        text: 'A photography contest',
        careers: multiChoiceOptions[5],
      },
    ],
    visual: {
      color: 'pink vertical-cross chomp-out',
      image: trophy,
      imageClass: 'bottom left',
    },
  },
  {
    /* spreadsheet Question 6 */
    type: 'multiChoice',
    prompt: 'Which is most true for you?',
    score: 1,
    options: [
      {
        text:
          'I love to go on trips and explore new places. I want to travel the world.',
        careers: getIds([0, 8, 11, 13, 14, 16, 19, 21, 23, 26, 29, 34, 35, 38]),
      },
      {
        text:
          "I love to create my own unique space at home—whether it's my room, desk, or locker.",
        careers: [],
      },
    ],
    visual: {
      color: 'white2 chomp parallax-out',
      image: helix,
      imageClass: 'rise helix',
    },
  },

  {
    /* spreadsheet Question 7 */
    type: 'multiChoice',
    prompt: 'Which kind of school project do you like best?',
    score: 1,
    options: [
      {
        text:
          'Group: I have fun working with a team. I like when everyone contributes their own unique talents.',
        careers: getIds([
          0,
          1,
          4,
          5,
          9,
          10,
          11,
          12,
          14,
          15,
          16,
          19,
          20,
          31,
          32,
          33,
          35,
          36,
          37,
          39,
        ]),
      },
      {
        text:
          'Individual: I can do things my own way, and I feel proud creating something on my own.',
        careers: [],
      },
    ],
    visual: {
      color: 'light-blue2 parallax vertical-cross-out',
      image: balloons,
      imageClass: 'rise long balloons',
    },
  },
  {
    /* spreadsheet Question 8 */
    type: 'multiChoice',
    prompt: 'Which is most true for you?',
    score: 1,
    options: [
      {
        text:
          'I am an adventurer and a doer. I like learning through hands-on experiments and activities.',
        careers: getIds([
          2,
          4,
          6,
          10,
          11,
          12,
          13,
          14,
          15,
          16,
          18,
          19,
          20,
          21,
          24,
          26,
          27,
          29,
          33,
          34,
          35,
          36,
          37,
          38,
        ]),
      },
      {
        text:
          "I am a dreamer and a thinker. I'm good at understanding metaphors and abstract ideas.",
        careers: [],
      },
    ],
    visual: {
      color: 'white2 vertical-cross chomp-out',
      image: hex,
      imageClass: 'float',
    },
  },
  {
    /* spreadsheet Question 9 */
    type: 'multiChoice',
    prompt:
      'True or false: You are a natural leader. You like making decisions and inspiring others.',
    score: 1,
    options: [
      {
        text: 'True',
        careers: getIds([2, 3, 9, 12, 13, 19, 26, 28, 30, 31, 37, 39]),
      },
      {
        text: 'False',
        careers: [],
      },
    ],
    visual: {
      color: 'orange chomp parallax-out',
      image: spirals,
      imageClass: 'rise long spirals',
    },
  },
  {
    /* spreadsheet Question 10 */
    type: 'multiChoice',
    prompt:
      'True or false: You like facts. You enjoy investigating, studying, and working with numbers.',
    score: 1,
    options: [
      {
        text: 'True',
        careers: getIds([
          0,
          1,
          2,
          5,
          8,
          9,
          12,
          13,
          14,
          15,
          16,
          18,
          19,
          20,
          21,
          22,
          25,
          26,
          27,
          30,
          31,
          32,
          33,
          34,
          35,
        ]),
      },
      {
        text: 'False',
        careers: [],
      },
    ],
    visual: {
      color: 'pink2 parallax vertical-cross-out',
      image: blocks,
      imageClass: 'rise long blocks',
    },
  },
  {
    /* spreadsheet Question 11 */
    type: 'multiChoice',
    prompt:
      'True or false: You like new ideas. You enjoy finding creative ways to solve problems.',
    score: 1,
    options: [
      {
        text: 'True',
        careers: getIds([
          1,
          2,
          3,
          4,
          6,
          7,
          8,
          9,
          10,
          11,
          17,
          18,
          22,
          23,
          24,
          28,
          29,
          35,
          36,
          37,
          38,
          39,
        ]),
      },
      {
        text: 'False',
        careers: [],
      },
    ],
    visual: {
      color: 'light-blue vertical-cross chomp-out',
      image: planes,
      imageClass: 'float spin',
    },
  },
  {
    /* spreadsheet Question 12 */
    type: 'multiChoice',
    prompt:
      "True or false: You are a planner. You're organized and love checklists.",
    score: 1,
    options: [
      {
        text: 'True',
        careers: getIds([
          0,
          1,
          2,
          3,
          4,
          5,
          6,
          7,
          8,
          11,
          13,
          15,
          16,
          19,
          20,
          21,
          22,
          23,
          25,
          26,
          27,
          28,
          30,
          31,
          32,
          33,
          34,
          35,
          36,
          37,
        ]),
      },
      {
        text: 'False',
        careers: [],
      },
    ],
    visual: {
      color: 'white3 chomp parallax-out',
      image: notes,
      imageClass: 'float',
    },
  },
  {
    /* spreadsheet Question 13 */
    type: 'multiChoice',
    prompt:
      'True or false: You are spontaneous. You face each day ready for infinite possibilities.',
    score: 1,
    options: [
      {
        text: 'True',
        careers: getIds([
          2,
          3,
          4,
          7,
          8,
          9,
          10,
          11,
          12,
          14,
          17,
          18,
          19,
          21,
          24,
          28,
          29,
          35,
          36,
          37,
          38,
          39,
        ]),
      },
      {
        text: 'False',
        careers: [],
      },
    ],
    visual: {
      color: 'pink2 parallax vertical-cross-out',
      image: ring,
      imageClass: 'float',
    },
  },
];

export default questions;
