import React from "react";

const copy = {
  home: {
    header: "¿Cómo usará sus talentos únicos para hacer la diferencia?",
    p1:
      "Hay miles de trabajos increíbles en materia de ciencia, tecnología, ingeniería, matemáticas, fabricación y diseño. Podría diseñar el siguiente panel solar o recurso de energía limpia, ayudar a sanar animales heridos o resolver ecuaciones aparentemente imposibles.",
    p2:
      "Responda este cuestionario y explore cómo una carrera CTIMFD puede ayudarlo a dar forma al futuro.",
    cta: "Comience el cuestionario",
  },
  quiz: {
    rankingPrompt: (
      <span>
        Elija sus <span>dos principales</span> prioridades.
      </span>
    ),
    multiPrompt: <span>Seleccione una respuesta.</span>,
    counter1: "pregunta",
    counter2: "de",
    cta: "Siguiente",
    lastCta: "Ver resultados",
  },
  results: {
    loading: "Calculando sus resultados",
    header: "Tenemos sus mejores coincidencias.",
    subheader:
      "Según sus respuestas únicas, ¡aquí le ofrecemos algunas posibilidades profesionales interesantes para que explore!",
    readMore: "Leer más",
    hide: "Ocultar",
    shareText: "Mi resultado es ",
    nextStepsHeader: "¡Explore más desde casa!",
    nextStepsBody: (
      <p>
        ¡Comience su recorrido hacia un futuro emocionante con estas divertidas
        actividades CTIMFD que utilizan materiales de toda la casa!
      </p>
    ),
    nextStepsCta: "OBTENGA MÁS INFORMACIÓN",
    tryAgainPrompt: "¿Desea intentar de nuevo?",
    tryAgainCta: "VUELVA A REALIZAR EL CUESTIONARIO",
    exploreMorePrompt: "Explore más carreras",
    exploreMoreCta: "MOSTRAR MIS RESULTADOS PRINCIPALES",
    exploreMoreCta2: "MOSTRAR RESULTADOS TODOS",
  },
  global: {
    footer: "Desarrollado con el apoyo de Johnson & Johnson",
  },
};

export default copy;
