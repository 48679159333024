import React from "react";
import { Redirect } from "react-router-dom";
import SubmitButton from "../elements/SubmitButton.js";

function Question(p) {
  /* before anything else, check to ensure user has completed
  all previous questions, and redirect if they haven't */

  if (
    p.transition === "current" &&
    p.index > 0 &&
    p.selections &&
    typeof p.selections[p.index - 1] === "undefined"
  ) {
    return <Redirect to={`/quiz/${p.index - 1}`} />;
  }

  const changeSelection = (i) => {
    const newArray = [...p.selections];
    let rankingArray =
      newArray[p.index] && typeof newArray[p.index] === "object"
        ? newArray[p.index]
        : [];
    switch (p.question.type) {
      case "ranking":
        if (i !== 0 && !i) {
          rankingArray = [];
        } else {
          if (rankingArray.length > 1) {
            rankingArray = [];
          }
          rankingArray.splice(rankingArray.length, 1, i);
        }
        newArray.splice(p.index, 1, rankingArray);
        // console.log(rankingArray);
        break;
      default:
        newArray.splice(p.index, 1, i);
        break;
    }

    p.setSelections(newArray);
  };

  const alreadySelected = (i) => {
    return (
      p.question.type === "ranking" &&
      typeof p.selections[p.index] === "object" &&
      p.selections[p.index].indexOf(i) !== -1
    );
  };

  const answers = p.question.options.map((option, i) => {
    return (
      <button
        className={`answer ${
          p.question.options.length > 3 ? "over-three-answers" : ""
        } ${
          (typeof p.selections &&
            typeof p.selections[p.index] !== "undefined" &&
            p.selections[p.index] === i) ||
          (typeof p.selections[p.index] === "object" &&
            p.selections[p.index].indexOf(i) !== -1)
            ? "selected"
            : "unselected"
        } ${
          p.question.type === "ranking" &&
          typeof p.selections[p.index] !== "undefined" &&
          p.selections[p.index][0] === i
            ? "first-rank"
            : ""
        } ${
          p.question.type === "ranking" &&
          typeof p.selections[p.index] === "object" &&
          p.selections[p.index][1] === i
            ? "second-rank"
            : ""
        }`}
        onClick={() => {
          if (p.question.type === "ranking" && alreadySelected(i)) {
            changeSelection(null);
          } else {
            changeSelection(i);
          }
        }}
        key={`question-${p.index}-option-${i}`}
      >
        <p>{option.text}</p>
      </button>
    );
  });

  let instructions =
    p.question.type === "ranking"
      ? p.copy.quiz.rankingPrompt
      : p.copy.quiz.multiPrompt;

  const classes = `quiz ${p.index === 0 ? "first-page" : ""} ${
    p.transitioning ? "transitioning" : "transitioned"
  } ${p.transition} ${p.question.visual.color}`;

  return (
    <main className={classes} key="quiz-main">
      <header className="quiz">
        {p.question.visual.image && (
          <section
            className={`image-container ${p.question.visual.imageClass}`}
          >
            <img
              className={`quiz-image ${p.question.visual.imageClass}`}
              src={p.question.visual.image}
              alt="quiz artwork"
            />
          </section>
        )}
        <h1>
          {p.question.type === "ranking" ? (
            <>
              {p.question.prompt}
              <br />
              {instructions}
            </>
          ) : (
            p.question.prompt
          )}
        </h1>
        <h2>{`${p.copy.quiz.counter1} ${p.index + 1} ${p.copy.quiz.counter2} ${
          p.questions.length
        }`}</h2>
      </header>
      <article className="quiz">
        <section className="answers-area">
          <h3>{instructions}</h3>
          {answers}
          <SubmitButton
            copy={p.copy}
            index={p.index}
            questions={p.questions}
            question={p.question}
            selections={p.selections}
            scoreCareers={p.scoreCareers}
            setTransitioning={p.setTransitioning}
          />
        </section>
      </article>
    </main>
  );
}

export default Question;
