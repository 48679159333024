import React, { useEffect } from "react";
import Result from "../elements/Result";
import { Link, Redirect } from "react-router-dom";
import headerImg from "../../assets/bubbles.png";
import arrowWhite from "../../assets/arrow_white.svg";
import arrowBlack from "../../assets/arrow_black.svg";

function Results(p) {
  
  useEffect(() => {
    if (p.path !== "results") p.setPath("results");
  });
  if (!p.careerResults || p.careerResults.length === 0)
    return <Redirect to={`/index.html`} />;

  const resultsList = p.careerResults.map((career, i) => {
    return (
      <Result
        key={`result-${i}`}
        copy={p.copy}
        career={career}
        index={i}
        language={p.language}
        threshold={p.threshold}
        showAllResults={p.showAllResults}
        careersExpanded={p.careersExpanded}
        setCareersExpanded={p.setCareersExpanded}
      />
    );
  }); 
  
  const toggleAllText = p.showAllResults
    ? p.copy.results.exploreMoreCta
    : p.copy.results.exploreMoreCta2;
    
  return (
    <>
      <main id="main" className="results">
        <header className="results">
          <img
            src={headerImg}
            alt="results header accent"
            className="results-header"
          />
          <h1>{p.copy.results.header}</h1> <p>{p.copy.results.subheader}</p>
        </header>
        <article className="results">{resultsList}</article>
        <article className="next-steps">
          <section>
            <h1>{p.copy.results.nextStepsHeader}</h1>
            {p.copy.results.nextStepsBody}
            <a href="https://www.stem2d.org/activities-at-home" target="blank">
              <button className="red">
                {p.copy.results.nextStepsCta}
                <img
                  alt="next button arrow"
                  className="button-arrow"
                  src={arrowWhite}
                />
              </button>
            </a>
          </section>
          <section className="try-again">
            <h2>{p.copy.results.tryAgainPrompt}</h2>
            <Link
              to="/quiz/0"
              onClick={() => {
                p.setSelections([]);
              }}
            >
              <button>
                <img
                  alt="next button arrow"
                  className="button-arrow flipped"
                  src={arrowWhite}
                />
                <div> {p.copy.results.tryAgainCta}</div>
              </button>
            </Link>
          </section>
          <section className="show-all">
            <h2>{p.copy.results.exploreMorePrompt}</h2>
            <button
              onClick={() => {
                const isIE11 =
                  !!window.MSInputMethodContext && !!document.documentMode;
                if (!isIE11) {
                  document
                    .getElementsByClassName("App")[0]
                    .scrollTo({ top: 0, behavior: "smooth" });
                  document
                    .getElementsByClassName("results")[0]
                    .scrollTo({ top: 0, behavior: "smooth" });
                  document
                    .getElementsByClassName("results")[1]
                    .scrollTo({ top: 0, behavior: "smooth" });
                }
                p.toggleAllResults(!p.showAllResults);
              }}
            >
              <div>{toggleAllText}</div>
              <img
                alt="next button arrow"
                className="button-arrow"
                src={arrowBlack}
              />
            </button>
          </section>
        </article>
      </main>
    </>
  );
}

export default Results;
