import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import arrow from "../../assets/arrow_white.svg";
const SubmitButton = (props) => {
  const p = props;
  const btnText = (
    <p>
      {p.copy.quiz.cta}{" "}
      <img alt="next button arrow" className="button-arrow" src={arrow} />
    </p>
  );
  let classes = "next red ";
  let [redirector, setRedirector] = useState(<></>);
  let [quizEnded, setQuizEnded] = useState(false);

  let scrollTop = () => {
    const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
    if (!isIE11)
      setTimeout(() => {
        document
          .getElementsByClassName("App")[0]
          .scrollTo({ top: 0, behavior: "smooth" });
      }, 1000);
  };

  let nextQuestion = () => {
    scrollTop();
    p.setTransitioning(true);
    setTimeout(() => {
      setRedirector(<Redirect to={`/quiz/${p.index + 1}`} />);
      p.setTransitioning(false);
    }, 4000);
  };

  let endQuiz = () => {
    scrollTop();
    setQuizEnded(true);
    p.scoreCareers();
    setTimeout(() => {
      setRedirector(<Redirect to={`/loading-results`} />);
    }, 1500);
  };
  if (p.questions.length - 1 === p.index) {
    if (typeof p.selections[p.index] === "undefined") classes += "disabled";
    return (
      <button
        onClick={() => endQuiz()}
        className={`results ${quizEnded ? "quiz-ended" : ""} ${classes}`}
      >
        <p>
          {p.copy.quiz.lastCta}{" "}
          <img alt="next button arrow" className="button-arrow" src={arrow} />
        </p>
        {redirector}
      </button>
    );
  }
  switch (p.question.type) {
    case "multiChoice":
      if (typeof p.selections[p.index] === "undefined") classes += "disabled";
      return (
        <button
          onClick={() => {
            nextQuestion();
          }}
          className={classes}
        >
          {btnText}

          {redirector}
        </button>
      );
    case "ranking":
      if (
        typeof p.selections[p.index] !== "object" ||
        p.selections[p.index].length < 2
      )
        classes += "disabled";
      if (p.questions.length - 1 === p.index)
        return (
          <button
            onClick={() => endQuiz()}
            className={`results ${quizEnded ? "quiz-ended" : ""} ${classes}`}
          >
            <p>
              See Results
              <img
                alt="next button arrow"
                className="button-arrow"
                src={arrow}
              />
            </p>
            {redirector}
          </button>
        );
      return (
        <button
          onClick={() => {
            nextQuestion();
          }}
          className={`second-step ${classes}`}
        >
          {btnText}
          {redirector}
        </button>
      );
    default:
      break;
  }
};
export default SubmitButton;
