import React, { useEffect } from "react";

import careersEnglish from "../../config/careers.js";
import questionsEnglish from "../../config/questions.js";
import copyEnglish from "../../config/copy.js";

import careersSpanish from "../../config/careers_esp.js";
import questionsSpanish from "../../config/questions_esp.js";
import copySpanish from "../../config/copy_esp.js";

function LanguageSelect(p) {
  const changeLanguage = (lang) => {
    if (p.language === lang) return;
    switch (lang) {
      case "english":
        p.setLanguage("english");
        p.setCopy(copyEnglish);
        p.setQuestions(questionsEnglish);
        p.setCareerResults(p.careersEng);
        break;
      default:
        // spanish
        p.setLanguage("spanish");
        p.setCopy(copySpanish);
        p.setQuestions(questionsSpanish);
        p.setCareerResults(p.careersEsp);
        break;
    }
  };

  useEffect(() => {
    const query = window.location.search;
    if (query.includes("lang=esp") && !p.usedQueryLang) {
      p.setUsedQueryLang(true);
      changeLanguage("spanish");
    }
  });

  return (
    <section id="language-select">
      <span
        className={`english ${p.language === "english" ? "selected" : ""}`}
        onClick={() => {
          changeLanguage("english");
        }}
      >
        ENGLISH
      </span>
      <span className="divider">{` | `}</span>
      <span
        className={`spanish ${p.language === "spanish" ? "selected" : ""}`}
        onClick={() => {
          changeLanguage("spanish");
        }}
      >
        ESPAÑOL
      </span>
    </section>
  );
}
export default LanguageSelect;
