import React, { useEffect } from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import Question from "./Question.js";

function Quiz(p) {
  useEffect(() => {
    if (p.path !== "quiz") p.setPath("quiz");
  });
  let match = useRouteMatch();

  const questions = p.questions;

  const questionRoutes = questions.map((question, i) => {
    return (
      <Route
        component={null}
        path={`${match.path}/${i}`}
        key={`question-route-${i}`}
      >
        <Question
          transition="current"
          selections={p.selections}
          setSelections={p.setSelections}
          question={questions[i]}
          questions={questions}
          scoreCareers={p.scoreCareers}
          transitioning={p.transitioning}
          setTransitioning={p.setTransitioning}
          index={i}
          copy={p.copy}
        />
        {i + 1 < questions.length && (
          <Question
            transition="next"
            tabindex={-1}
            selections={p.selections}
            setSelections={p.setSelections}
            question={questions[i + 1]}
            questions={questions}
            scoreCareers={p.scoreCareers}
            transitioning={p.transitioning}
            setTransitioning={p.setTransitioning}
            index={i + 1}
            copy={p.copy}
          />
        )}
      </Route>
    );
  });
  return <Switch key="quiz-switch">{questionRoutes}</Switch>;
}

export default Quiz;
